import { gql } from '@apollo/client';

export const  GET_MANIFIESTOS = gql`
query GetManifiestos($residuo: String, $startDate: Int, $endDate: Int) {
  getManifiestos(residuo: $residuo, start_date: $startDate, end_date: $endDate) {
    _id
    tipo
    orden
    fecha_entrada
    no_manifiesto
    estado
    razon_social
    registro_semarnat
    residuo
    corrosivo
    reactivo
    explosivo
    toxico
    inflamable
    biologico_infeccioso
    clave
    densidad
    cantidad_lts
    factor_lts
    cantidad_kg
    factor_kg
    descripcion
    manifiesto
    cantidad_litros
    cantidad_ton
    cantidad_m3
    piezas
    cve_mes
    fase
    transportista
    no_autorizacion_transportista
    cantidad_residuo
    destino_final
    numero_manifiesto
    no_autorizacion_destino_final
    manifiestos_salida {
      tipo
      fecha_entrada
      no_manifiesto
      razon_social
      estado
      registro_semarnat
      residuo
      corrosivo
      reactivo
      explosivo
      toxico
      inflamable
      biologico_infeccioso
      clave
      densidad
      cantidad_lts
      factor_lts
      cantidad_kg
      factor_kg
      descripcion
      manifiesto
      cantidad_litros
      cantidad_ton
      cantidad_m3
      piezas
      cve_mes
      fase
      transportista
      no_autorizacion_transportista
      cantidad_residuo
      destino_final
      numero_manifiesto
      no_autorizacion_destino_final
    }
  }
}
`;

export const DELETE_MANIFIESTO = gql`
  mutation Mutation($id: ID!, $residuo: String) {
    deleteManifiesto(_id: $id, residuo: $residuo){
      _id
      residuo
    }
  }
`;

export const UPDATE_MANIFIESTO_ENTRADA = gql`
  mutation Mutation($id: ID!, $idData: ManifiestoIDContent, $data: ManifiestoContent) {
    updateManifiesto(_id: $id, id_data: $idData, data: $data) {
      _id
      tipo
      fecha_entrada
      no_manifiesto
      razon_social
      estado
      registro_semarnat
      residuo
      corrosivo
      reactivo
      explosivo
      toxico
      inflamable
      biologico_infeccioso
      clave
      densidad
      cantidad_lts
      factor_lts
      cantidad_kg
      factor_kg
      descripcion
      manifiesto
      cantidad_litros
      cantidad_ton
      cantidad_m3
      piezas
      cve_mes
      fase
      transportista
      no_autorizacion_transportista
      cantidad_residuo
      destino_final
      numero_manifiesto
      no_autorizacion_destino_final
      manifiestos_salida {
        tipo
        fecha_entrada
        no_manifiesto
        razon_social
        estado
        registro_semarnat
        residuo
        corrosivo
        reactivo
        explosivo
        toxico
        inflamable
        biologico_infeccioso
        clave
        densidad
        cantidad_lts
        factor_lts
        cantidad_kg
        factor_kg
        descripcion
        manifiesto
        cantidad_litros
        cantidad_ton
        cantidad_m3
        piezas
        cve_mes
        fase
        transportista
        no_autorizacion_transportista
        cantidad_residuo
        destino_final
        numero_manifiesto
        no_autorizacion_destino_final
      }
    }
  }
`;
export const UPDATE_MANIFIESTO_SALIDA = gql`
  mutation Mutation($id: ID!, $idData: ManifiestoIDContent, $data: ManifiestoContent) {
    updateManifiesto(_id: $id, id_data: $idData, data: $data) {
      _id
      residuo
      manifiestos_salida {
        tipo
        fecha_entrada
        no_manifiesto
        razon_social
        estado
        registro_semarnat
        residuo
        corrosivo
        reactivo
        explosivo
        toxico
        inflamable
        biologico_infeccioso
        clave
        densidad
        cantidad_lts
        factor_lts
        cantidad_kg
        factor_kg
        descripcion
        manifiesto
        cantidad_litros
        cantidad_ton
        cantidad_m3
        piezas
        cve_mes
        fase
        transportista
        no_autorizacion_transportista
        cantidad_residuo
        destino_final
        numero_manifiesto
        no_autorizacion_destino_final
      }
    }
  }
`;

export const ADD_MANIFIESTO = gql`
  mutation Mutation($fechaEntrada: Int, $razonSocial: String, $noManifiesto: String, $estado: String, $residuo: String, $toxico: String, $inflamable: String, $densidad: String, $cantidadLts: String, $cantidadKg: String, $fase: String, $transportista: String, $noAutorizacionTransportista: String, $cantidadResiduo: String, $destinoFinal: String, $numeroManifiesto: String, $noAutorizacionDestinoFinal: String, $manifiesto: String, $tipo: String) {
    createManifiesto(fecha_entrada: $fechaEntrada, razon_social: $razonSocial, no_manifiesto: $noManifiesto, estado: $estado, residuo: $residuo, toxico: $toxico, inflamable: $inflamable, densidad: $densidad, cantidad_lts: $cantidadLts, cantidad_kg: $cantidadKg, fase: $fase, transportista: $transportista, no_autorizacion_transportista: $noAutorizacionTransportista, cantidad_residuo: $cantidadResiduo, destino_final: $destinoFinal, numero_manifiesto: $numeroManifiesto, no_autorizacion_destino_final: $noAutorizacionDestinoFinal, manifiesto: $manifiesto, tipo: $tipo) {
      _id
      fecha_entrada
      no_manifiesto
      razon_social
      estado
      registro_semarnat
      residuo
      corrosivo
      reactivo
      explosivo
      toxico
      inflamable
      biologico_infeccioso
      clave
      densidad
      cantidad_lts
      factor_lts
      cantidad_kg
      factor_kg
      descripcion
      manifiesto
      cantidad_litros
      cantidad_ton
      cantidad_m3
      piezas
      cve_mes
      fase
      transportista
      no_autorizacion_transportista
      cantidad_residuo
      destino_final
      numero_manifiesto
      no_autorizacion_destino_final
      tipo
    }
  }
`;

export const ADD_MANIFIESTO_SALIDA = gql`
  mutation Mutation($id: ID!, $idData: ManifiestoIDContent, $data: ManifiestoContent) {
    updateManifiesto(_id: $id, id_data: $idData, data: $data) {
      _id
      residuo
      manifiestos_salida {
        tipo
        fecha_entrada
        no_manifiesto
        razon_social
        estado
        registro_semarnat
        residuo
        corrosivo
        reactivo
        explosivo
        toxico
        inflamable
        biologico_infeccioso
        clave
        densidad
        cantidad_lts
        factor_lts
        cantidad_kg
        factor_kg
        descripcion
        manifiesto
        cantidad_litros
        cantidad_ton
        cantidad_m3
        piezas
        cve_mes
        fase
        transportista
        no_autorizacion_transportista
        cantidad_residuo
        destino_final
        numero_manifiesto
        no_autorizacion_destino_final
      }
    }
  }
`;