import { gql } from '@apollo/client';

export const  GET_COMPANIES_LIST = gql`
  query Query {
    getCompanies {
      _id
      type
      contact
      phone
      name
      scraps {
        agua
        aceite
        basura
        lodo
        porrones_tinas
        filtro_de_aire
        lodo_tipo
        basura_tipo
        aceite_tipo_cargo
        agua_tipo_cargo
        basura_tipo_cargo
        lodo_tipo_cargo
        porrones_tinas_tipo_cargo
        filtro_de_aire_tipo_cargo
      }
      active
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation Mutation($type: String!, $name: String!, $scraps: ScrapsContent!, $contact: String, $phone: String) {
    createCompany(type: $type, name: $name, scraps: $scraps, contact: $contact, phone: $phone) {
      _id
      type
      contact
      phone
      name
      scraps {
        agua
        aceite
        basura
        basura_tipo
        lodo
        lodo_tipo
        aceite_tipo_cargo
        agua_tipo_cargo
        basura_tipo_cargo
        lodo_tipo_cargo
      }
      active
    }
  }
`;

export const UPDATE_COMPANY = gql `
  mutation Mutation($id: ID!, $name: String, $active: Boolean, $scraps: ScrapsContent, $type: String) {
    updateCompany(_id: $id, name: $name, active: $active, scraps: $scraps, type: $type) {
      _id
      type
      contact
      phone
      name
      scraps {
        agua
        aceite
        basura
        basura_tipo
        lodo
        lodo_tipo
      }
      active
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation Mutation($id: ID!) {
    deleteCompany(_id: $id) {
      _id
    }
  }
`