import { gql } from '@apollo/client';

export const  GET_TYPE_EXPENSE = gql`
  query Query {
    getBillTypes {
      _id
      tipo
    }
  }
`;

export const  GET_EXPENSE = gql`
  query Query {
    getBills {
      _id
      nombre
      monto
      tipo
    }
  }
`;

export const  GET_BILLS = gql`
  query Query($startDate: Int, $endDate: Int) {
    getBillsPayments(start_date: $startDate, end_date: $endDate) {
      _id
      nombre
      monto
      tipo
      fecha_pago
    }
  }
`;

export const ADD_BILL = gql`
  mutation AddBills($bills: [BillPaymentsContent]) {
    addBills(bills: $bills) {
      _id
      nombre
      monto
      tipo
      fecha_pago
    }
  }
`;

export const ADD_TYPE_EXPENSE = gql`
  mutation Mutation($tipo: String) {
    createBillType(tipo: $tipo) {
      _id
      tipo
    }
  }
`;

export const ADD_EXPENSE = gql`
  mutation Mutation($monto: Float!, $nombre: String!, $tipo: String) {
    createBill(monto: $monto, nombre: $nombre, tipo: $tipo) {
      _id
      nombre
      monto
      tipo
    }
  }
`;

export const DELETE_EXPENSE = gql`
  mutation Mutation($id: ID!) {
    deleteBill(_id: $id) {
      _id
      nombre
      monto
      tipo
    }
  }
`;

export const DELETE_TYPE_EXPENSE = gql`
  mutation DeleteBillType($id: ID!) {
    deleteBillType(_id: $id) {
      _id
      tipo
    }
  }
`;
export const UPDATE_BILL = gql`
  mutation UpdateBill($id: ID!, $monto: Float) {
    updateBill(_id: $id, monto: $monto) {
      _id
      nombre
      monto
      tipo
    }
  }
`;