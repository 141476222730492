import React from 'react';
import {
  BadgeDelta,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
} from "@tremor/react";

const TableByBills = ({ dataBy }) => {
  if (!dataBy || Object.keys(dataBy).length === 0) {
    return (
      <div className="my-5">
        <Card>
          <Text>No hay datos disponibles en este mes.</Text>
        </Card>
      </div>
    );
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const allWeeks = Array.from(
    new Set(Object.values(dataBy).flat().map((item) => item.week))
  );

  const calculateWeeklySums = () => {
    const weeklySums = {};
    Object.values(dataBy).flat().forEach((item) => {
      if (!weeklySums[item.tipo]) {
        weeklySums[item.tipo] = {};
      }
      if (!weeklySums[item.tipo][item.week]) {
        weeklySums[item.tipo][item.week] = 0;
      }
      weeklySums[item.tipo][item.week] += item.monto;
    });
    return weeklySums;
  };

  const calculateTotalSums = () => {
    const totalSums = {};
    Object.values(dataBy).flat().forEach((item) => {
      if (!totalSums[item.tipo]) {
        totalSums[item.tipo] = 0;
      }
      totalSums[item.tipo] += item.monto;
    });
    return totalSums;
  };

  const calculateWeeklyTotalSum = () => {
    const weeklyTotalSum = {};
    Object.values(dataBy).flat().forEach((item) => {
      if (!weeklyTotalSum[item.week]) {
        weeklyTotalSum[item.week] = 0;
      }
      weeklyTotalSum[item.week] += item.monto;
    });
    return weeklyTotalSum;
  };

  const weeklySums = calculateWeeklySums();
  const totalSums = calculateTotalSums();
  const weeklyTotalSum = calculateWeeklyTotalSum();

  const globalTotalSum = Object.values(totalSums).reduce((acc, curr) => acc + curr, 0);

  return (
    <div className="my-5">
      <Card className="static">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell className="max-w-44">
                <p>Gastos Fijos</p>
              </TableHeaderCell>
              {allWeeks.map((week) => (
                <TableHeaderCell key={week}>
                  {capitalizeFirstLetter(week)}
                </TableHeaderCell>
              ))}
              <TableHeaderCell>Total</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(weeklySums).map((tipo) => (
              <TableRow key={tipo}>
                <TableCell>{tipo}</TableCell>
                {allWeeks.map((week) => (
                  <TableCell key={week}>
                    {weeklySums[tipo][week] ? (
                      <Text>${weeklySums[tipo][week].toLocaleString('en-US')}</Text>
                    ) : (
                      <Text>$0</Text>
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  <Text>${totalSums[tipo].toLocaleString('en-US')}</Text>
                </TableCell>
              </TableRow>
            ))}
            <TableRow className="!border-solid !border-t-2 !border-blue-500/100">
              <TableCell>
              </TableCell>
              {allWeeks.map((week) => (
                <TableCell key={week}>
                  <strong>${weeklyTotalSum[week]?.toLocaleString('en-US') || 0}</strong>
                </TableCell>
              ))}
              <TableCell>
                <strong>${globalTotalSum.toLocaleString('en-US')}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default TableByBills;
