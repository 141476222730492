import React, { useEffect, useState } from 'react'
import { Button, Card, DatePicker, Title, Divider, Select, SelectItem } from '@tremor/react';
import { es } from 'date-fns/locale'
import BuscarEmpresa from '../empresas/BuscarEmpresa';
import { RiAddLine, RiRefreshLine } from '@remixicon/react';
import FichaTecnica from './FichaTecnica';
import { ADD_RECORD, GET_RECORDS, GET_SCRAP_QUANTITIES } from '../../services/registros/registroService';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import Notification from '../shared/NotificationAlert';
import { ADD_FINANCE, GET_FINANCE, GET_FINANCE_TOTAL } from '../../services/finanzas/finanzasService';

const CrearRegistro = () => {
    const { loading, error, data } = useQuery(GET_SCRAP_QUANTITIES);

    const [addRecord, { dataRecord }] = useMutation(ADD_RECORD, {
        refetchQueries: [
          {
            query: GET_SCRAP_QUANTITIES,
            variables: { startDate: moment().startOf('year').unix(), endDate: moment().endOf('day').unix() },
          },
          { query: GET_RECORDS }
        ]
    });

    const [addFinance, { dataFinance }] = useMutation(ADD_FINANCE, {
        refetchQueries: [
          { query: GET_FINANCE }, 
          { query: GET_FINANCE_TOTAL },
          { query: GET_SCRAP_QUANTITIES },
        ]
    });

    const today = new Date();
    const [companyValue, setEmpresaValue] = useState('');
    const [selectedOption, setSelectedOption] = useState({
        option: '',
        value: ''
    });
    const [selectedResiduo, setSelectedResiduo] = useState('');
    const [selectedDate, setSelectedDate] = useState(today);
    const [fichaTecnicaData, setFichaTecnica] = useState({
        cantidad: '',
        cantidadMan: '',
        contenedor: '',
        densidad: '',
        derrames: false,
        operador: '',
        cantTambos: ''
    });

    const [resetValue, setReset] = useState(false);
    const [isTambo, setTamboValue] = useState(false);

    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const friendlyNames = {
      aceite: 'Aceite',
      basura: 'Basura',
      agua: 'Agua',
      lodo: 'Lodo',
      porrones_tinas: 'Porrones / Tinas',
      filtro_de_aire: 'Filtro de Aire'
    };
    
    const friendlyResiduoName = friendlyNames[selectedResiduo] || selectedResiduo;

    const handleAddRecord = () => {
        // Validar los campos
        const isCompanyValid = companyValue && companyValue._id;
        const isOptionValid = selectedOption && selectedOption.value;
        const isResiduoValid = selectedResiduo;
        const isDateValid = today instanceof Date && !isNaN(today);
        const isQuantityValid = fichaTecnicaData.cantidad && !isNaN(parseFloat(fichaTecnicaData.cantidad));
        const isDerramesValid = fichaTecnicaData.derrames !== undefined;

        // Si alguno de los campos no es válido, mostrar una notificación y salir de la función
        if (!isCompanyValid || !isOptionValid || !isResiduoValid || !isDateValid || !isQuantityValid || !isDerramesValid) {
            handleShowNotification('Por favor, complete todos los campos requeridos.');
            return;
        }

        // Validación específica para el campo cantTambos
        if (selectedResiduo === 'basura' && unidad === 'kilos' && companyValue.scraps.basura_tipo === 'TAMBO' && fichaTecnicaData.cantTambos === '') {
            handleShowNotification('Por favor, ingrese la cantidad de tambos de basura.');
            return;
        }
        
        if (selectedResiduo === 'lodo' && unidad === 'kilos' && companyValue.scraps.lodo_tipo === 'TAMBO' && fichaTecnicaData.cantTambos === '') {
            handleShowNotification('Por favor, ingrese la cantidad de tambos de lodo.');
            return;
        }

        let total;
        const tipoCargoResiduo = companyValue.scraps[`${selectedResiduo}_tipo_cargo`];

        if (tipoCargoResiduo === "cobro") {
            if (selectedResiduo === 'basura' && unidad === 'kilos' && companyValue.scraps.basura_tipo === 'TAMBO') {
                // Si la unidad es 'kilos' y el tipo de basura es 'TAMBO', multiplicar por la cantidad de tambores
                total = fichaTecnicaData.cantTambos * companyValue.scraps[selectedResiduo];
            } 
            
            else if (selectedResiduo === 'lodo' && unidad === 'kilos' && companyValue.scraps.lodo_tipo === 'TAMBO') {
                // Si la unidad es 'kilos' y el tipo de basura es 'TAMBO', multiplicar por la cantidad de tambores
                total = fichaTecnicaData.cantTambos * companyValue.scraps[selectedResiduo];
            } 
            
            else {
                // Si no, multiplicar por la cantidad normal
                total = fichaTecnicaData.cantidad * companyValue.scraps[selectedResiduo];
            }
        } else if (tipoCargoResiduo === "pago") {
            if (selectedResiduo === 'basura' && unidad === 'kilos' && companyValue.scraps.basura_tipo === 'TAMBO') {
                // Si la unidad es 'kilos' y el tipo de basura es 'TAMBO', multiplicar por la cantidad de tambores
                total = -1 * (fichaTecnicaData.cantTambos * companyValue.scraps[selectedResiduo]);
            } 

            else if (selectedResiduo === 'lodo' && unidad === 'kilos' && companyValue.scraps.lodo_tipo === 'TAMBO') {
                // Si la unidad es 'kilos' y el tipo de basura es 'TAMBO', multiplicar por la cantidad de tambores
                total = -1 * (fichaTecnicaData.cantTambos * companyValue.scraps[selectedResiduo]);
            } 
            
            else {
                // Si no, multiplicar por la cantidad normal
                total = -1 * (fichaTecnicaData.cantidad * companyValue.scraps[selectedResiduo]);
            }
        }
    addRecord({  
        variables: {
        company: {_id: companyValue._id},
        type: selectedOption.value,
        scrap: selectedResiduo,
        entryDate: moment(selectedDate).unix(),
        operatorName: fichaTecnicaData.operador,
        quantity: {quantity: parseFloat(fichaTecnicaData.cantidad), type: unidad},
        manifiestedQuantity: {quantity: parseFloat(fichaTecnicaData.cantidad ? parseFloat(fichaTecnicaData.cantidad) : 0), type: unidad},
        container: fichaTecnicaData.contenedor,
        density: fichaTecnicaData.densidad,
        leaks: fichaTecnicaData.derrames,
        total: total
        }
    }).then(result => {
        handleShowNotification(`El registro de ${selectedOption.option} de "${friendlyResiduoName}" fue agregado con éxito.`)
        handleClearStates();

        let record_id = result.data.createRecord._id;
        let total_entry = 0;
        let total_exit = 0;
        total < 0 ? total_exit = Math.abs(total) : total_entry = total;
        if (selectedOption.option === 'entrada') {
          addFinance({ 
            variables: { 
              recordId: record_id,
              type: tipoCargoResiduo, 
              entryDate: moment(selectedDate).unix(), 
              scrap: selectedResiduo, 
              description: fichaTecnicaData.operador + ' ' + fichaTecnicaData.cantidad + ' ' +  unidad, 
              entry: total_entry, 
              exit: total_exit,
              company: {_id: companyValue._id} 
            } 
          }).then(result => {
              handleClearStates();
              
          }).catch(error => {
              const errorMessage = error.message;
              console.log(errorMessage)
              handleShowNotification(errorMessage, 'Oops falló algo!')
            });
          }
        }).catch(error => {
            const errorMessage = error.message;
            console.log(errorMessage)
        });

    };

    const handleSelectResiduo = (residuo) => {
        setSelectedResiduo(residuo);
        setReset(true)
    };

    const handleSelectRegistro = (option) => {
        setSelectedOption(option);
    };

    const handleClearStates = () => {
        handleSelectResiduo('');
        handleSelectRegistro('');
        setReset(true)
    };

    const handleFichaTecnica = (formData) => {
        setFichaTecnica(formData)
    };

    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000); // Oculta la notificación después de 3 segundos
    };

    const handleSelectEmpresa = (companySelected) => {
        if (typeof companySelected === 'object' && companySelected !== null) {
            setEmpresaValue(companySelected);
        } else {
            // Si companySelected es una cadena vacía o no está definido
            if (!companySelected) {
                // Ejecutar handleClearStates solo si companyValue está vacío
                if (!companyValue) {
                    handleClearStates();
                }
            }
            // Si companySelected es una cadena vacía o null
            {
                companySelected === '' &&
                    setEmpresaValue(companySelected);
            }
        }
    }

    let totalScrapValue = 0;

    if (data?.getQuantityCalculations && data?.getQuantityCalculations.byScrap) {
        const scrapData = data.getQuantityCalculations.byScrap.find(scrap => scrap._id === selectedResiduo);
        if (scrapData) {
            totalScrapValue = scrapData.total;
        }
    }

    const unidad = selectedResiduo === 'agua' || selectedResiduo === 'aceite' ? 'litros' :
        selectedResiduo === 'porrones_tinas' || selectedResiduo === 'filtro_de_aire' ? 'piezas' : 'kilos';

    useEffect(() => {
        if (unidad === 'kilos' && companyValue.scraps) {
            if (selectedResiduo === 'basura' && companyValue.scraps.basura_tipo === 'TAMBO' || (selectedResiduo === 'lodo' && companyValue.scraps.lodo_tipo === 'TAMBO')) {
                setTamboValue(true);
            } else {
                setTamboValue(false);
            }
        } else {
            setTamboValue(false);
        }
    }, [companyValue.scraps, unidad, selectedResiduo]);

    useEffect(() => {
        if (resetValue) {
            setReset(false);
        }
    }, [resetValue]);

    return (
        <div className='w-full'>
            {showNotification && <Notification message={message} />}
            <div className="flex flex-col md:flex-row mt-5 md:gap-5">
                <Card className="w-full md:w-1/3">
                    <BuscarEmpresa onSeleccionChange={handleSelectEmpresa} filtro={true} />
                    <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mt-5">Tipo de registro</Title>
                    <div className='flex flex-col md:flex-row gap-3 mt-2 md:w-1/2'>
                        <Button
                            className={`px-4 py-2 rounded w-full  ${selectedOption.option === 'entrada' ? 'bg-[#38B866] text-white' : 'bg-slate-300'} border-transparent hover:border-transparent hover:bg-[#38B866] hover:text-white`}
                            onClick={() => handleSelectRegistro({
                                option: 'entrada',
                                value: 'entry'
                            })}
                            disabled={!companyValue}
                        >Entrada
                        </Button>
                        <Button
                            className={`px-4 py-2 rounded w-full ${selectedOption.option === 'salida' ? 'bg-red-500 text-white' : 'bg-slate-300'} border-transparent hover:border-transparent hover:bg-red-500 hover:text-white`}
                            onClick={() => handleSelectRegistro({
                                option: 'salida',
                                value: 'exit'
                            })}
                            disabled={!companyValue}
                        >Salida
                        </Button>
                    </div>
                    <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mt-5">Tipo de residuo</Title>
                    <div className="flex flex-col gap-3 mt-2">
                        <Select
                            placeholder={'Selecciona residuo'}
                            onChange={handleSelectResiduo}
                            value={selectedResiduo}
                        >
                            <SelectItem className='cursor-pointer' value="aceite">Aceite</SelectItem>
                            <SelectItem value="basura">Basura</SelectItem>
                            <SelectItem value="agua">Agua</SelectItem>
                            <SelectItem value="lodo">Lodo</SelectItem>
                            <SelectItem value="porrones_tinas">Porrones / Tinas</SelectItem>
                            <SelectItem value="filtro_de_aire">Filtro de Aire</SelectItem>
                        </Select>
                        {selectedResiduo &&
                            <Card className='flex flex-col md:flex-row md:w-full md:justify-center md:gap-5 items-center h-auto'>
                                <p>Cantidad total de {friendlyResiduoName}</p>
                                <Title className='text-2xl'>{totalScrapValue} {unidad}</Title>
                            </Card>
                        }
                    </div>
                    <div className='flex flex-col gap-3 mt-5 mb-3 md:mb-0'>
                        <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Ficha técnica</Title>
                        <DatePicker
                            lang='es' // Establece el idioma como español
                            value={selectedDate}
                            selected={selectedDate}
                            onValueChange={(date) => setSelectedDate(date)}
                            locale={es} // Pasa el locale español
                            placeholder="Seleccionar"
                            maxDate={new Date()} // Establece la fecha máxima como hoy
                            disabled={false} // Hace que el DatePicker no sea editable
                            enableClear={false} // Deshabilita la opción de borrar la fecha
                        />
                    </div>
                </Card>
                <div className='md:w-2/3'>
                    <FichaTecnica tipo={isTambo} residuo={selectedResiduo} onValuesChange={handleFichaTecnica} reset={resetValue} />
                    <Card className='flex w-full md:w-auto flex-col justify-center md:justify-around md:flex-row gap-3 my-5 md:mt-5'>
                        <p>* En caso de ocasionar derrame, deberá ser limpiado por el operador del camión.</p>
                        <Button
                            className='bg-slate-500 border-transparent hover:border-transparent hover:bg-slate-700'
                            icon={RiRefreshLine}
                            onClick={() => handleClearStates()}
                        > Limpiar
                        </Button>
                        <Button
                            className='bg-[#38B866] border-transparent hover:border-transparent hover:bg-green-600'
                            icon={RiAddLine}
                            onClick={() => handleAddRecord()}
                        >Capturar {selectedOption.option}
                        </Button>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default CrearRegistro
