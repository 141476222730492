import React from 'react'
import PageHeader from '../components/sidebar/PageHeader'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@tremor/react'
import { RiContrast2Fill, RiContrastDrop2Line, RiDeleteBin7Line, RiDropLine, RiEdit2Line, RiFloodFill, RiFloodLine, RiHistoryLine, RiOilFill, RiOilLine } from '@remixicon/react'
import TablaManifiestos from '../components/manifiestos/TablaManifiestos'
import CrearManifiesto from '../components/manifiestos/CrearManifiesto'

const Manifiestos = () => {
  return (
    <div  className='w-full'>
      <PageHeader pageTitle={`Manifiestos`} />
      <TabGroup className="w-auto mx-6 md:mx-10">
          <TabList className="mt-4">
            <Tab icon={RiEdit2Line}>Capturar</Tab>
            <Tab icon={RiOilLine}>Aceite</Tab>
            <Tab icon={RiDeleteBin7Line}>Acopio</Tab>
          </TabList>
          <TabPanels>
              <TabPanel>
                <CrearManifiesto/>
              </TabPanel>
              <TabPanel>
                <TablaManifiestos residuoManifiesto='aceite'/>
              </TabPanel>
              <TabPanel>
                <TablaManifiestos residuoManifiesto='resto'/>
              </TabPanel>
          </TabPanels>
      </TabGroup>
    </div>
  )
}

export default Manifiestos
