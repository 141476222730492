import React from 'react'
import PageHeader from '../components/sidebar/PageHeader'
import Eventos from '../components/calendario/Eventos'

const Calendario = () => {
  return (
    <div className='w-full'>
      <PageHeader pageTitle="Calendario" />
      <Eventos/>
    </div>
  )
}

export default Calendario
