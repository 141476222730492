import React from "react";
import {
    BadgeDelta,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
} from "@tremor/react";

const TableBy = ({ dataBy }) => {
  if (!dataBy || Object.keys(dataBy).length === 0) {
    return (
      <div className="my-5">
        <Card>
          <Text>No hay datos disponibles en este mes.</Text>
        </Card>
      </div>
    );
  }

  const allWeeks = Array.from(
    new Set(
      Object.values(dataBy)
        .flat()
        .map((item) => item.week)
    )
  );

  const calculateOverallWeeklySums = (type) => {
    const weeklySums = {};
    allWeeks.forEach(week => {
      weeklySums[week] = Object.values(dataBy).flat()
        .filter(item => item.week === week)
        .reduce((sum, item) => sum + item[type], 0);
    });
    return weeklySums;
  };

  const overallEntrySums = calculateOverallWeeklySums('entry');
  const overallExitSums = calculateOverallWeeklySums('exit');

  const calculateTotalSums = (type) => {
    const totalSums = {};
    Object.keys(dataBy).forEach(key => {
      totalSums[key] = dataBy[key].reduce((sum, item) => sum + item[type], 0);
    });
    return totalSums;
  };

  const calculateGrandTotalSum = (type) => {
    const totalSums = calculateTotalSums(type);
    return Object.values(totalSums).reduce((sum, value) => sum + value, 0);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatString = (string) => {
    return string
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="my-5">
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>
                <BadgeDelta deltaType="increase" isIncreasePositive={true}>
                    Ingresos
                </BadgeDelta>
              </TableHeaderCell>
              {allWeeks.map(week => (
                <TableHeaderCell key={week}>{capitalizeFirstLetter(week)}</TableHeaderCell>
              ))}
              <TableHeaderCell>Total</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(dataBy).map((key) => {
              const totalEntry = dataBy[key].reduce((sum, item) => sum + item.entry, 0);
              return (
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell>{formatString(key)}</TableCell>
                    {allWeeks.map(week => {
                      const weekData = dataBy[key].find(item => item.week === week);
                      return (
                        <TableCell key={week}>
                          {weekData ? (
                            <Text>${weekData.entry.toLocaleString('en-US')}</Text>
                          ) : (
                            <Text>N/A</Text>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Text>${totalEntry.toLocaleString('en-US')}</Text>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            <TableRow className="!border-solid !border-t-2 !border-green-500/100">
              <TableCell></TableCell>
              {allWeeks.map(week => (
                <TableCell key={week}>
                  <Text className="font-bold">${overallEntrySums[week].toLocaleString('en-US')}</Text>
                </TableCell>
              ))}
              
              <TableCell>
                <Text className="font-bold">${calculateGrandTotalSum('entry').toLocaleString('en-US')}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <Card className="mt-8">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>
              <BadgeDelta deltaType="decrease" isIncreasePositive={true}>
                Gastos
              </BadgeDelta>
              </TableHeaderCell>
              {allWeeks.map(week => (
                <TableHeaderCell key={week}>{capitalizeFirstLetter(week)}</TableHeaderCell>
              ))}
              <TableHeaderCell>Total</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(dataBy).map((key) => {
              const totalExit = dataBy[key].reduce((sum, item) => sum + item.exit, 0);
              return (
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell>{formatString(key)}</TableCell>
                    {allWeeks.map(week => {
                      const weekData = dataBy[key].find(item => item.week === week);
                      return (
                        <TableCell key={week}>
                          {weekData ? (
                            <Text>${weekData.exit.toLocaleString('en-US')}</Text>
                          ) : (
                            <Text>N/A</Text>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Text>${totalExit.toLocaleString('en-US')}</Text>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            <TableRow className="!border-solid !border-t-2 !border-red-500/100">
              <TableCell></TableCell>
              {allWeeks.map(week => (
                <TableCell key={week}>
                  <Text className="font-bold">${overallExitSums[week].toLocaleString('en-US')}</Text>
                </TableCell>
              ))}
              <TableCell>
                <Text className="font-bold">${calculateGrandTotalSum('exit').toLocaleString('en-US')}</Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default TableBy;
