import { gql } from '@apollo/client';

export const  GET_FINANCE = gql`
query GetFinances($scrap: String, $startDate: Int, $endDate: Int) {
    getFinances(scrap: $scrap, start_date: $startDate, end_date: $endDate) {
      company {
        name
      }
      description
      entry
      entry_date
      exit
      scrap
      type
      _id
      record_id
    }
  }
`;


export const ADD_FINANCE = gql`
mutation Mutation($type: String, $company: FinanceCompanyContent, $entryDate: Int, $scrap: String, $description: String, $entry: Float, $exit: Float, $recordId: ID) {
  createFinance(type: $type, company: $company, entry_date: $entryDate, scrap: $scrap, description: $description, entry: $entry, exit: $exit, record_id: $recordId) {
    description
    entry
    entry_date
    exit
    scrap
    type
    company {
      name
    }
  }
}
`;

export const GET_FINANCE_TOTAL = gql`
query GetFinanceCalculations($startDate: Int, $endDate: Int) {
  getFinanceCalculations(start_date: $startDate, end_date: $endDate) {
    total_entry
    total_exit
    byCompany {
      _id
      totalEntry
      totalExit
    }
  }
}
`;

export const UPDATE_FINANCE = gql`
  mutation Mutation($id: ID!, $data: FinanceContent) {
    updateFinance(_id: $id, data: $data) {
      _id
      record_id
      company {
        _id
        name
      }
      type
      scrap
      entry_date
      description
      entry
      exit
    }
  }
`;

export const GET_BALANCE = gql`
query Query($startDate: Int, $endDate: Int) {
  getBalanceCalculation(start_date: $startDate, end_date: $endDate) {
    total_entry
    total_exit
  }
}
`;


export const DELETE_FINANCE = gql`
mutation DeleteFinance($id: ID!) {
  deleteFinance(_id: $id) {
    _id
    company {
      name
      _id
    }
    type
    scrap
    entry_date
    description
    entry
    exit
  }
}
`;