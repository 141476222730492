import React, { useState } from 'react';

const InputNumber = ({ value: propValue, onValueChange, initialValue }) => {
  const [value, setValue] = useState(propValue || '');

  // Si initialValue cambia, actualiza el valor del input
  if (initialValue !== undefined && value !== initialValue.toString()) {
    setValue(initialValue.toString());
  }

  const handleChange = (event) => {
    const inputValue = event.target.value;
    // Validar la longitud de la entrada del usuario
    if (inputValue.length <= 6 && /^-?\d*\.?\d{0,2}$/.test(inputValue)) {
      setValue(inputValue);
      onValueChange(inputValue);
    }
  };

  return (
    <input 
      type="text" 
      value={value} 
      onChange={handleChange} 
      placeholder="Ingrese un número" 
      maxLength={6} 
      className="border border-gray-300 rounded-lg px-3 py-4 focus:outline-none focus:border-blue-500 w-32 h-9"
    />
  );
};

export default InputNumber;
