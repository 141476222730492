import React, { useState, useEffect } from 'react';
import { Button, Card, Select, SelectItem, Switch, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput, Title } from '@tremor/react';
import { RiPencilFill } from '@remixicon/react';
import BuscarEmpresa from './BuscarEmpresa';
import InputNumber from '../shared/InputNumber';
import { GET_COMPANIES_LIST, UPDATE_COMPANY } from '../../services/empresas/companyService';
import { useMutation } from '@apollo/client';
import ErrorModal from '../ErrorModal';

const EditarEmpresa = () => {
    const [updateCompany, { data }] = useMutation(UPDATE_COMPANY, {
        refetchQueries: [{ query: GET_COMPANIES_LIST }]
    });

    const [companyValue, setEmpresaValue] = useState('');
    const [scrapsValue, setScraps] = useState({
        aceite: companyValue.scraps?.aceite,
        basura: companyValue.scraps?.basura,
        basura_tipo: companyValue.scraps?.basura_tipo,
        agua: companyValue.scraps?.agua,
        lodo_tipo: companyValue.scraps?.lodo_tipo,
        lodo: companyValue.scraps?.lodo,
        porrones_tinas: companyValue.scraps?.porrones_tinas,
        filtro_de_aire: companyValue.scraps?.filtro_de_aire,
        aceite_tipo_cargo: companyValue.scraps?.aceite_tipo_cargo,
        agua_tipo_cargo: companyValue.scraps?.agua_tipo_cargo,
        basura_tipo_cargo: companyValue.scraps?.basura_tipo_cargo,
        lodo_tipo_cargo: companyValue.scraps?.lodo_tipo_cargo,
        filtro_de_aire_tipo_cargo: companyValue.scraps?.filtro_de_aire_tipo_cargo,
      });

       // Estados para almacenar los valores de los switches
    const [lodoTipoCargoValue, setLodoTipoCargoValue] = useState(false);
    const [porronesTinasTipoCargoValue, setPorronesTinasTipoCargoValue] = useState(false);
    const [filtroAireTipoCargoValue, setFiltroAireTipoCargoValue] = useState(false);
    const [basuraTipoCargoValue, setBasuraTipoCargoValue] = useState(false);
    const [aceiteTipoCargoValue, setAceiteTipoCargoValue] = useState(false);
    const [aguaTipoCargoValue, setAguaTipoCargoValue] = useState(false);
    const [lodoValue, setLodoValue] = useState(false);
    const [basuraValue, setBasuraValue] = useState(false);
    const [nameCompanyValue, setNameCompany] = useState(companyValue.name);
    const [contactValue, setContact] = useState(companyValue.contact);
    const [phoneValue, setPhone] = useState(companyValue.phone);
    const [statusCompanyValue, setStatusCompany] = useState(companyValue.active);
    const [typeValue, setTypeValue] = useState(companyValue.type); 

    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');

    const handleNameChange = (value) => {
        setNameCompany(value);
    };

    const handleContactChange = (value) => {
        setContact(value);
    };
    const handlePhoneChange = (value) => {
        setPhone(value);
    };

    const handleStatusChange = (value) => {
        setStatusCompany(value);
    };

    const handleLodoChange = () => {
        setLodoValue(!lodoValue);
        const newLodoTipo = !lodoValue ? 'TAMBO' : 'KG';
        setScraps(prevState => ({
        ...prevState,
        lodo_tipo: newLodoTipo
        }));
      };
    
      const handleBasuraChange = () => {
        setBasuraValue(!basuraValue);
        const newBasuraTipo = !basuraValue ? 'TAMBO' : 'KG';
        setScraps(prevState => ({
        ...prevState,
        basura_tipo: newBasuraTipo
        }));
      };

    const handleLodoTipoCargoChange = () => {
        setLodoTipoCargoValue(!lodoTipoCargoValue);
        const newLodoTipoCargoValue = !lodoTipoCargoValue ? 'pago' : 'cobro';
        setScraps(prevState => ({
        ...prevState,
        lodo_tipo_cargo: newLodoTipoCargoValue
        }));
    };
    const handlePorronesTinasTipoCargoChange = () => {
        setPorronesTinasTipoCargoValue(!porronesTinasTipoCargoValue);
        const newPorronesTinasTipoCargoValue= !porronesTinasTipoCargoValue ? 'pago' : 'cobro';
        setScraps(prevState => ({
        ...prevState,
        porrones_tinas_tipo_cargo: newPorronesTinasTipoCargoValue
        }));
    };
    const handleFiltroAireTipoCargoChange = () => {
        setFiltroAireTipoCargoValue(!filtroAireTipoCargoValue);
        const newFiltroAireTipoCargoValue= !filtroAireTipoCargoValue ? 'pago' : 'cobro';
        setScraps(prevState => ({
        ...prevState,
        filtro_de_aire_tipo_cargo: newFiltroAireTipoCargoValue
        }));
    };

    const handleBasuraTipoCargoChange = () => {
        setBasuraTipoCargoValue(!basuraTipoCargoValue);
        const newBasuraTipoCargoValue = !basuraTipoCargoValue ? 'pago' : 'cobro';
        setScraps(prevState => ({
        ...prevState,
        basura_tipo_cargo: newBasuraTipoCargoValue
        }));
    };

    const handleAceiteTipoCargoChange = () => {
        setAceiteTipoCargoValue(!aceiteTipoCargoValue);
        const newAceiteTipoCargoValue = !aceiteTipoCargoValue ? 'pago' : 'cobro';
        setScraps(prevState => ({
        ...prevState,
        aceite_tipo_cargo: newAceiteTipoCargoValue
        }));
    };

    const handleAguaTipoCargoChange = () => {
        setAguaTipoCargoValue(!aguaTipoCargoValue);
        const newAguaTipoCargoValue = !aguaTipoCargoValue ? 'pago' : 'cobro';
        setScraps(prevState => ({
        ...prevState,
        agua_tipo_cargo: newAguaTipoCargoValue
        }));
    };

    const handleScrapsChange = (inputName, newValue) => {
        setScraps(prevValues => ({
            ...prevValues,
            [inputName]: newValue
        }));
    };

    const handleUpdateCompany = () => {
        
        const aceite = parseFloat(scrapsValue.aceite || "0");
        const basura = parseFloat(scrapsValue.basura || "0");
        const agua = parseFloat(scrapsValue.agua || "0");
        const lodo = parseFloat(scrapsValue.lodo || "0");
        const basura_tipo = scrapsValue.basura_tipo
        const lodo_tipo = scrapsValue.lodo_tipo
        const aceite_tipo_cargo = scrapsValue.aceite_tipo_cargo
        const agua_tipo_cargo = scrapsValue.agua_tipo_cargo
        const basura_tipo_cargo = scrapsValue.basura_tipo_cargo
        const lodo_tipo_cargo = scrapsValue.lodo_tipo_cargo
        const porrones_tinas = parseFloat(scrapsValue.porrones_tinas || "0");
        const porrones_tinas_tipo_cargo = scrapsValue.porrones_tinas_tipo_cargo
        const filtro_de_aire = parseFloat(scrapsValue.filtro_de_aire || "0");
        const filtro_de_aire_tipo_cargo = scrapsValue.filtro_de_aire_tipo_cargo
        const newScraps = { aceite, basura, basura_tipo, agua, lodo, lodo_tipo, aceite_tipo_cargo, agua_tipo_cargo, basura_tipo_cargo, lodo_tipo_cargo, porrones_tinas, porrones_tinas_tipo_cargo, filtro_de_aire, filtro_de_aire_tipo_cargo };

        updateCompany({ 
            variables: { 
                id: companyValue._id,
                name: nameCompanyValue,
                active: statusCompanyValue,
                scraps: newScraps,
                type: typeValue,
                contact: contactValue,
                phone: phoneValue,
            }})
            .then(result => {
            handleOpenMessage(`La empresa ${nameCompanyValue} fue actualizada correctamente `)

            })
            .catch(error => {
                const errorMessage = error.message;
                handleOpenMessage(errorMessage)
            });
    };
    
    const handleSelectEmpresa = (companySelected) => {
        if (typeof companySelected === 'object' && companySelected !== null) {
            setEmpresaValue(companySelected);
        } else {
            {companySelected === '' && 
                setEmpresaValue(companySelected);
            }
        }
    }

    useEffect(() => {
        if (companyValue) {
            setNameCompany(companyValue.name);
            setContact(companyValue.contact);
            setPhone(companyValue.phone);
            setStatusCompany(companyValue.active)
            setTypeValue(companyValue.type)
        }
        if (companyValue.scraps) {
            setScraps({
                aceite: companyValue.scraps.aceite || 0,
                basura: companyValue.scraps.basura || 0,
                basura_tipo: companyValue.scraps.basura_tipo,
                agua: companyValue.scraps.agua || 0,
                lodo: companyValue.scraps.lodo || 0,
                porrones_tinas: companyValue.scraps.porrones_tinas || 0,
                filtro_de_aire: companyValue.scraps.filtro_de_aire || 0,
                lodo_tipo: companyValue.scraps.lodo_tipo,
                aceite_tipo_cargo: companyValue.scraps.aceite_tipo_cargo,
                agua_tipo_cargo: companyValue.scraps.agua_tipo_cargo,
                basura_tipo_cargo: companyValue.scraps.basura_tipo_cargo,
                lodo_tipo_cargo: companyValue.scraps.lodo_tipo_cargo,
                porrones_tinas_tipo_cargo: companyValue.scraps.porrones_tinas_tipo_cargo,
                filtro_de_aire_tipo_cargo: companyValue.scraps.filtro_de_aire_tipo_cargo,
            });
            companyValue.scraps.basura_tipo === 'KG' ? setBasuraValue(false) : setBasuraValue(true)
            companyValue.scraps.lodo_tipo === 'KG' ? setLodoValue(false) : setLodoValue(true)
            companyValue.scraps.aceite_tipo_cargo === 'cobro' ? setAceiteTipoCargoValue(false) : setAceiteTipoCargoValue(true)
            companyValue.scraps.agua_tipo_cargo === 'cobro' ? setAguaTipoCargoValue(false) : setAguaTipoCargoValue(true)
            companyValue.scraps.basura_tipo_cargo === 'cobro' ? setBasuraTipoCargoValue(false) : setBasuraTipoCargoValue(true)
            companyValue.scraps.lodo_tipo_cargo === 'cobro' ? setLodoTipoCargoValue(false) : setLodoTipoCargoValue(true)
            companyValue.scraps.porrones_tinas_tipo_cargo === 'cobro' ? setPorronesTinasTipoCargoValue(false) : setPorronesTinasTipoCargoValue(true)
            companyValue.scraps.filtro_de_aire_tipo_cargo === 'cobro' ? setFiltroAireTipoCargoValue(false) : setFiltroAireTipoCargoValue(true)
        }
    }, [companyValue]);
    
    const handleOpenMessage = (message) => {
        setMessage(message);
        setOpenModal(true);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
      };

  return (
    <div>
        <Card className="mx-auto w-auto mt-5">
            <BuscarEmpresa onSeleccionChange={handleSelectEmpresa} filtro={false}/>
        </Card>
        { companyValue && 
            <div className='mt-5'>
                <div className='flex flex-col md:flex-row mt-5 md:gap-5'>
                    <Card className="w-full md:w-1/3">
                        <ErrorModal isOpen={openModal} onClose={handleCloseModal} errorMessage={message} />
                        <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Datos de la empresa</Title>
                        <div className='flex flex-col w-full mb-5 gap-2 mt-5'>
                            <p className="md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Nombre de la empresa</p>
                            <TextInput placeholder="Ingresa nombre de la empresa.." value={nameCompanyValue || ''} onValueChange={handleNameChange} />
                            <p className="md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Contacto</p>
                            <TextInput placeholder="Nombre de contacto.." value={contactValue || ''} onValueChange={handleContactChange} />
                            <p className="md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Teléfono de contacto</p>
                            <TextInput placeholder="Número de contacto.." value={phoneValue || ''} onValueChange={handlePhoneChange} />
                            <p className="md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Tipo</p>
                            <Select defaultValue={typeValue} value={typeValue} onChange={setTypeValue}>
                                <SelectItem value="empresa">Empresa</SelectItem>
                                <SelectItem value="recolectora">Recolectora</SelectItem>
                            </Select>
                            <p className="md:text-left text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mt-5">Estatus de la empresa</p>
                            <div className='flex gap-3'>
                                <p>{ statusCompanyValue ? 'Activada' : 'Desactivada'}</p>
                                <Switch
                                    id="switch"
                                    name="switch"
                                    checked={statusCompanyValue}
                                    onChange={handleStatusChange}
                                    />
                            </div>
                        </div>
                    </Card>
                    <Card className='w-full md:w-2/3 mt-5 md:mt-0'>
                        <Title className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Tabulador</Title>
                        <Table className='mt-5'>
                            <TableHead>
                                <TableRow>
                                <TableHeaderCell>Residuo</TableHeaderCell>
                                <TableHeaderCell>Tabulador</TableHeaderCell>
                                <TableHeaderCell>Unidad</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Aceite</TableCell>
                                    <TableCell>
                                        <InputNumber
                                            initialValue={scrapsValue.aceite}
                                            value={scrapsValue.aceite}
                                            onValueChange={newValue => handleScrapsChange('aceite', newValue)}
                                            />
                                    </TableCell>
                                    <TableCell>Litros</TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor="switchTipoCargoAceite" className={`mr-2 ${!aceiteTipoCargoValue ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name="switchTipoCargoAceite"
                                                className='items-center'
                                                checked={aceiteTipoCargoValue}
                                                onChange={handleAceiteTipoCargoChange}
                                            />
                                            <label htmlFor="switchTipoCargoAceite" className={`ml-2 ${aceiteTipoCargoValue ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Basura</TableCell>
                                    <TableCell>
                                        <InputNumber 
                                            initialValue={scrapsValue.basura}
                                            value={scrapsValue.basura}
                                            onValueChange={newValue => handleScrapsChange('basura', newValue)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex'>
                                            <label htmlFor="switchBasura" className="mr-2">KG</label>
                                            <Switch
                                                name="switchBasura"
                                                className='items-center'
                                                checked={basuraValue}
                                                onChange={handleBasuraChange}
                                            />
                                            <label htmlFor="switchBasura" className="ml-2">TAMBO</label>
                                            
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor="switchTipoCargoBasura" className={`mr-2 ${!basuraTipoCargoValue ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name="switchTipoCargoBasura"
                                                className='items-center'
                                                checked={basuraTipoCargoValue}
                                                onChange={handleBasuraTipoCargoChange}
                                            />
                                            <label htmlFor="switchTipoCargoBasura" className={`ml-2 ${basuraTipoCargoValue ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Agua</TableCell>
                                    <TableCell>
                                        <InputNumber 
                                            initialValue={scrapsValue.agua} 
                                            value={scrapsValue.agua}  
                                            onValueChange={newValue => handleScrapsChange('agua', newValue)}
                                            />
                                    </TableCell>
                                    <TableCell>Litros</TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor="switchTipoCargoAgua" className={`mr-2 ${!aguaTipoCargoValue ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name="switchTipoCargoAgua"
                                                className='items-center'
                                                checked={aguaTipoCargoValue}
                                                onChange={handleAguaTipoCargoChange}
                                            />
                                            <label htmlFor="switchTipoCargoAgua" className={`ml-2 ${aguaTipoCargoValue ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lodo</TableCell>
                                    <TableCell>
                                        <InputNumber 
                                            initialValue={scrapsValue.lodo} 
                                            value={scrapsValue.lodo}  
                                            onValueChange={newValue => handleScrapsChange('lodo', newValue)}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <div className='flex'>
                                            <label htmlFor="switchLodo" className="mr-2">KG</label>
                                            <Switch
                                                name="switchLodo"
                                                className='items-center'
                                                checked={lodoValue}
                                                onChange={handleLodoChange}
                                            />
                                            <label htmlFor="switchLodo" className="ml-2">TAMBO</label>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor="switchTipoCargoLodo" className={`mr-2 ${!lodoTipoCargoValue ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name="switchTipoCargoLodo"
                                                className='items-center'
                                                checked={lodoTipoCargoValue}
                                                onChange={handleLodoTipoCargoChange}
                                            />
                                            <label htmlFor="switchTipoCargoLodo" className={`ml-2 ${lodoTipoCargoValue ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Porrones / Tinas</TableCell>
                                    <TableCell>
                                        <InputNumber 
                                            initialValue={scrapsValue.porrones_tinas} 
                                            value={scrapsValue.porrones_tinas}  
                                            onValueChange={newValue => handleScrapsChange('porrones_tinas', newValue)}
                                        />
                                    </TableCell>
                                    <TableCell>Pieza</TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor="switchTipoCargoPorrones" className={`mr-2 ${!porronesTinasTipoCargoValue ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name="switchTipoCargoPorrones"
                                                className='items-center'
                                                checked={porronesTinasTipoCargoValue}
                                                onChange={handlePorronesTinasTipoCargoChange}
                                            />
                                            <label htmlFor="switchTipoCargoPorrones" className={`ml-2 ${porronesTinasTipoCargoValue ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Filtro de Aire</TableCell>
                                    <TableCell>
                                        <InputNumber 
                                            initialValue={scrapsValue.filtro_de_aire} 
                                            value={scrapsValue.filtro_de_aire}  
                                            onValueChange={newValue => handleScrapsChange('filtro_de_aire', newValue)}
                                        />
                                    </TableCell>
                                    <TableCell>Pieza</TableCell>
                                    <TableCell>
                                        <div className='flex items-center mb-2'>
                                            <label htmlFor="switchTipoCargoFiltro" className={`mr-2 ${!filtroAireTipoCargoValue ? 'font-semibold' : ''}`}>Cobro</label>
                                            <Switch
                                                name="switchTipoCargoFiltro"
                                                className='items-center'
                                                checked={filtroAireTipoCargoValue}
                                                onChange={handleFiltroAireTipoCargoChange}
                                            />
                                            <label htmlFor="switchTipoCargoFiltro" className={`ml-2 ${filtroAireTipoCargoValue ? 'font-semibold' : ''}`}>Pago</label>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            
                        </Table>
                    </Card>
                </div>
                <div className='flex w-full md:w-auto flex-col justify-center md:justify-end md:flex-row gap-2 my-3 md:mt-5'>
                    <Button 
                        className='bg-blue-600' 
                        icon={RiPencilFill}
                        onClick={() => handleUpdateCompany()}
                        >
                        Actualizar
                    </Button>
                </div> 
            </div>
            }
        </div>
    )
}

export default EditarEmpresa
