import React, { createContext, useContext } from 'react';
import useAuthTokens from './useAuthTokens';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const tokens = useAuthTokens();

  return (
    <AuthContext.Provider value={tokens}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
