import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Registro from '../pages/Registro';
import Manifiestos from '../pages/Manifiestos';
import Home from '../pages/Home';
import Tablero from '../pages/Tablero';
import Empresas from '../pages/Empresas';
import Usuarios from '../pages/Usuarios';
import SideBar from '../components/sidebar/SideBar';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import logoCIBA from '../assets/cibalogo.png';
import GastosFijos from '../pages/GastosFijos';
import Calendario from '../pages/Calendario';
import { useQuery } from '@apollo/client';
import { GET_USER_LIST } from '../services/usuarios/userServices';

const ProtectedComponent = ({ component }) => {
  const Cp = withAuthenticationRequired(component, {
    onRedirecting: () =>
      <div className='w-full h-screen flex flex-col justify-center items-center'>
        <img src={logoCIBA} className='object-contain h-24 w-40 m-5' alt="Logo" />
        <p>Cargando...</p>
      </div>,
  });
  return <Cp />;
}

// Componente para proteger rutas según el rol del usuario
const RoleProtectedRoute = ({ component: Component, allowedRoles, userRole }) => {
  if (!userRole) {
    return <Navigate to="/" />;
  }

  return allowedRoles.includes(userRole) ? <Component /> : <Navigate to="/" />;
};

const RoutesApp = () => {
  const { isAuthenticated, user } = useAuth0();
  const { loading, error, data } = useQuery(GET_USER_LIST);
  const users = data?.listUsers?.users;

  const findUserByEmail = (users, email) => {
    return users?.find(u => u.email === email) || null;
  };

  const foundUser = findUserByEmail(users, user?.email);
  const userRole = foundUser?.role;

  // Si ocurre un error al obtener los datos, redirige a Home
  if (error) {
    console.log(error)
    return <Navigate to="/" />;
  }

  return (
    <div className='flex flex-col md:flex-row'>
      {isAuthenticated && <SideBar />}
      <Routes className="flex-grow">
        {/* Rutas protegidas según el rol del usuario */}
        <Route
          path="/finanzas"
          element={
            <RoleProtectedRoute
              component={Home}
              allowedRoles={['admin', 'master']}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/tablero"
          element={
            <RoleProtectedRoute
              component={Tablero}
              allowedRoles={['admin', 'master']}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/gastosfijos"
          element={
            <RoleProtectedRoute
              component={GastosFijos}
              allowedRoles={['admin', 'master']}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/usuarios"
          element={
            <RoleProtectedRoute
              component={Usuarios}
              allowedRoles={['admin', 'master']}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/empresas"
          element={
            <RoleProtectedRoute
              component={Empresas}
              allowedRoles={['admin', 'master']}
              userRole={userRole}
            />
          }
        />
        {/* Rutas visibles para todos */}
        <Route path="/" element={<ProtectedComponent component={Registro} />} />
        <Route path="/manifiestos" element={<ProtectedComponent component={Manifiestos} />} />
        <Route path="/calendario" element={<ProtectedComponent component={Calendario} />} />
        
        {/* Redirige cualquier ruta desconocida a Home */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default RoutesApp;
