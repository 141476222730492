import { Button, Dialog, DialogPanel } from '@tremor/react';
import React from 'react';

function ErrorModal({ isOpen, onClose, errorMessage }) {
  return (
    <Dialog open={isOpen} onClose={onClose} static={true}>
      <DialogPanel>
        <p className="mt-2 leading-6 text-gray-700">{errorMessage}</p>
        <Button className="mt-8 w-full" onClick={onClose}>
          Aceptar
        </Button>
      </DialogPanel>
    </Dialog>
  );
}

export default ErrorModal;