import { useMutation } from '@apollo/client';
import { RiAddLine, RiMoneyDollarBoxLine, RiRefreshLine } from '@remixicon/react'
import { Button, Card, Select, SelectItem, TextInput } from '@tremor/react'
import React, { useState } from 'react'
import Notification from '../shared/NotificationAlert';
import { ADD_FINANCE, GET_BALANCE, GET_FINANCE } from '../../services/finanzas/finanzasService';
import moment from 'moment';
import HistorialFinanzas from './HistorialFinanzas';
import TotalFinanzas from './TotalFinanzas';

const CrearFinanza = () => {
    const [variablesDate, setVariablesDateValue] = useState({
      startDate: moment().startOf('day').unix(),
      endDate: moment().endOf('day').unix()
    });
    const [addFinance, { data }] = useMutation(ADD_FINANCE, {
      refetchQueries: [
        { query: GET_FINANCE }, 
        { query: GET_BALANCE }, 
      ]
    });

    const [descValue, setDescValue] = useState('');
    const [entryValue, setEntryValue] = useState(0);
    const [exitValue, setExitValue] = useState(0);
    const [tipoValue, setTipoValue] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    //Notification values
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const handleShowNotification = (message) => {
      setMessage(message)
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000); // Oculta la notificación después de 3 segundos
    };

    const handlDescripcionChange = (value) => {
        setDescValue(value);
        validateForm(value, tipoValue);
    };

    const handleCantChange = (value) => {
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value)) {
          if (tipoValue === 'entrada') {
              setEntryValue(value);
              setExitValue('0');
            } else{
              setExitValue(value);
              setEntryValue('0');
            }
        }
    };
    const handleTipoChange = (value) => {
        setTipoValue(value);
        validateForm(descValue, value);
        
    };
    
    const handleClearStates = () => {
      handleTipoChange('');
      handleCantChange('');
      handlDescripcionChange('');
    };

    const [updateTrigger, setUpdateTrigger] = useState(0);

    const handleAddFinance = () => {
        addFinance({ 
          variables: { 
            type: tipoValue, 
            entryDate: moment().unix(), 
            scrap: "otro", 
            description: descValue, 
            entry: parseFloat(entryValue), 
            exit: parseFloat(exitValue)
          } 
        }).then(result => {
            handleShowNotification(`Se ingresó ${tipoValue} de ${tipoValue === 'entrada' ? '$' + entryValue : '$' + exitValue} con éxito.`)
            setUpdateTrigger(prev => prev + 1);
        }).catch(error => {
            const errorMessage = error.message;
            console.log(errorMessage)
            handleShowNotification(errorMessage, 'Oops falló algo!')
          });
          handleClearStates()
      };

      const validateForm = (desc, tipo)  => {
        if (desc && tipo ) {
          setIsButtonEnabled(true);
        } else {
          setIsButtonEnabled(false);
        }
      };

  return (
    <div>
      {showNotification && <Notification message={message} />}
      <Card className="mx-auto w-auto m-6 md:mx-10">
        <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Ingresar movimiento</h3>
        <div className='flex flex-col md:flex-row justify-between mt-5 gap-2'>
          <div className="w-full md:w-1/5 mt-4 md:mt-0">
            <p>Tipo:</p>
            <Select value={tipoValue} defaultValue="1" placeholder='Selecciona..' onValueChange={setTipoValue}>
              <SelectItem value="entrada">Entrada</SelectItem>
              <SelectItem value="gasto">Gasto</SelectItem>
              <SelectItem value="prestamo">Prestamo</SelectItem>
              <SelectItem value="comision">Comisión</SelectItem>
            </Select>
          </div>
          <div className="w-full md:w-2/5">
            <p>Descripción</p>
            <TextInput 
              value={descValue} 
              onValueChange={handlDescripcionChange} 
              placeholder="Ingresa descripción.." 
            />
          </div>
          <div className="w-full md:w-2/5">
            <p>Cantidad</p>
            <TextInput 
              icon={RiMoneyDollarBoxLine}
              value={tipoValue === 'entrada' ? entryValue : exitValue} 
              onValueChange={handleCantChange} 
              maxLength={8}
              disabled={!isButtonEnabled}
              placeholder="Ingresa cantidad.." 
            />
          </div>
        </div>
        <div className='flex w-full md:w-auto flex-col justify-center md:justify-end md:flex-row gap-3 mt-7 md:mt-5'>
            <Button 
                className='bg-slate-500 border-transparent hover:border-transparent hover:bg-slate-700' 
                icon={RiRefreshLine}
                onClick={() => handleClearStates()}
                > Limpiar
            </Button>
            <Button 
                className='bg-[#38B866] border-transparent hover:border-transparent hover:bg-green-600' 
                icon={RiAddLine}
                onClick={() => handleAddFinance()}
                disabled={!isButtonEnabled}
                >Agregar
            </Button>
        </div>
      </Card>
      <div className='flex flex-col md:flex-row gap-5 md:gap-10 mx-6 md:mx-0 mb-5 md:mb-0'>
        <HistorialFinanzas updateTrigger={updateTrigger}/>
        <TotalFinanzas variablesDate={variablesDate} updateTrigger={updateTrigger}/>
      </div>
    </div>
  )
}

export default CrearFinanza
