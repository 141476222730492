import React from 'react'
import PageHeader from '../components/sidebar/PageHeader'
import AgregarUsuario from '../components/usuarios/AgregarUsuario'
import ListaUsuarios from '../components/usuarios/ListaUsuarios'

const Usuarios = () => {
  return (
    <div className='w-full'>
      <PageHeader pageTitle="Usuarios" />
      <AgregarUsuario />
      <ListaUsuarios />
    </div>
  )
}

export default Usuarios
