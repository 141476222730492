import { gql } from '@apollo/client';

export const GET_EVENT = gql`
  query Query {
    getCalendarEvents {
        title
        start
        end
        rrule
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation Mutation($events: [CalendarEventContent]) {
    createCalendarEvent(events: $events) {
        title
        start
        end
        rrule
    }
  }
`;

export const DELETE_EVENT = gql`
    mutation DeleteCalendarEvent($title: String!) {
        deleteCalendarEvent(title: $title) {
            title
            start
            end
            rrule
        }
    }
`;