import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from './AuthProvider';

const httpLink = createHttpLink({
  uri: 'https://test.odmacode.com/graphql', // URL de tu servidor GraphQL
});

const AuthenticatedApolloProvider = ({ children }) => {
  const { idToken } = useAuth();
  const [client, setClient] = useState(null);
  const accessToken = localStorage.getItem('idToken');

  useEffect(() => {
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          }
        };
      });

      const apolloClient = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      });

      setClient(apolloClient);
  }, [accessToken]);

  if (!client) {
    return <div>Loading...</div>; // O cualquier indicador de carga que desees mostrar
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;
