import { TableHead, TableHeaderCell, TableRow } from '@tremor/react'
import React from 'react'

const ManifiestoTableHead = ({tipo}) => {
  return (
    <TableHead>
        <TableRow className='text-xs'>
            {tipo !== 'editar' ? <TableHeaderCell>Acciones</TableHeaderCell> : ''}
            <TableHeaderCell>Tipo</TableHeaderCell>
            {tipo !== 'editar' ? <TableHeaderCell >Fecha de entrada</TableHeaderCell> : ''}
            <TableHeaderCell>No. de manifiesto</TableHeaderCell>
            <TableHeaderCell>Razón Social</TableHeaderCell>
            <TableHeaderCell>Estado</TableHeaderCell>
            <TableHeaderCell>Residuo</TableHeaderCell>
            <TableHeaderCell>Tóxico</TableHeaderCell>
            <TableHeaderCell>Inflamable</TableHeaderCell>
            <TableHeaderCell>Densidad</TableHeaderCell>
            <TableHeaderCell>Cantidad (Lts)</TableHeaderCell>
            <TableHeaderCell>Manifiesto</TableHeaderCell>
            <TableHeaderCell>Fase</TableHeaderCell>
            <TableHeaderCell>Transportista</TableHeaderCell>
            <TableHeaderCell>No. de autorización (Transportista)</TableHeaderCell>
            <TableHeaderCell>Cantidad Residuo</TableHeaderCell>
            <TableHeaderCell>Destino Final</TableHeaderCell>
            <TableHeaderCell>No. Manifiesto</TableHeaderCell>
            <TableHeaderCell>No. de autorización (Destino Final)</TableHeaderCell>
        </TableRow>
    </TableHead>
  )
}

export default ManifiestoTableHead
