import React, { useState, useEffect } from 'react';

const Notification = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`fixed z-[9999] top-5 left-0 right-0 bg-gray-800 bg-opacity-75 text-white p-4 text-center transition-opacity duration-500 rounded-lg ${visible ? 'opacity-100' : 'opacity-0'}`} style={{ maxWidth: '500px', margin: '0 auto' }}>
      <p>{message}</p>
    </div>
  );
};

export default Notification;
