import {
    Card,
    List,
    ListItem
  } from '@tremor/react';
  
import React, { useEffect, useState } from 'react'
import { GET_BALANCE } from '../../services/finanzas/finanzasService';
import { useQuery } from '@apollo/client';

const TotalFinanzas = ({ variablesDate, updateTrigger }) => {
  const { loading, error, data, refetch  } = useQuery(GET_BALANCE);

  const [totalEntry, setTotalEntry] = useState(0);
  const [totalExit, setTotalExit] = useState(0);


  useEffect(() => {
    if (data && data.getBalanceCalculation) {
      setTotalEntry(data?.getBalanceCalculation?.total_entry || 0);
      setTotalExit(data?.getBalanceCalculation?.total_exit || 0);
    }
  }, [data]);
  
  useEffect(() => {
    refetch();
  }, [updateTrigger, refetch]);

  const totalBalance = totalEntry - totalExit;

  if (loading) return <Card className="w-1/4 mr-10 h-max ">Cargando...</Card>;
  
  return (
    <Card className="md:w-1/4 mr-10 h-max ">
      <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">Balance</h3>
      <List className="mt-2">
        <ListItem>
          <span>Entradas:</span>
          <span>${loading ? 'Cargando...' : totalEntry}</span>
        </ListItem>
        <ListItem>
          <span>Salidas:</span>
          <span>${loading ? 'Cargando...' : totalExit}</span>
        </ListItem>
        <ListItem>
          <span><strong>Total:</strong></span>
          <span><strong>${loading ? 'Cargando...' : totalBalance}</strong></span>
        </ListItem>
      </List>
    </Card>
  )
}

export default TotalFinanzas