import React, { useState } from 'react'
import { Button, Dialog, DialogPanel } from '@tremor/react';
import { RiDeleteBin2Fill } from '@remixicon/react';
import { useMutation } from '@apollo/client';
import { DELETE_EVENT, GET_EVENT } from '../../services/calendario/calendarioService';
import Notification from '../shared/NotificationAlert';

const EliminarEvento = ({ isDeleteDialogOpen, setDeleteDialogOpen, eventName, handleEventDeleted }) => {
    const [deleteEvent] = useMutation(DELETE_EVENT, {
        refetchQueries: [{ query: GET_EVENT }],
        onCompleted: () => {
            handleShowNotification(`Se eliminó el evento "${eventName}" con éxito .`)
            setDeleteDialogOpen(false);
            handleEventDeleted(eventName);
        },
        onError: (error) => {
            console.error('Error al eliminar el evento:', error.message);
        }
    });

    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    const handleDeleteEvent = () => {
        deleteEvent({ 
            variables: { 
                title: eventName
            } 
        });
    };
  return (
    <div>
        {showNotification && <Notification message={message} />}
        <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} static={true} className="z-[100] flex">
            <DialogPanel className="flex justify-center flex-col w-full">
                <h2 className="text-lg font-semibold mb-2">¿Deseas eliminar el evento?</h2>
                
                <div className="mb-4">
                    <h1>{eventName}</h1>
                </div>
                <div>
                    <Button
                        className='bg-red-600 border-transparent hover:border-transparent hover:bg-red-700 w-full' 
                        icon={RiDeleteBin2Fill}
                        onClick={()=> handleDeleteEvent(eventName)}
                    >Eliminar evento</Button>
                </div>
            </DialogPanel>
        </Dialog>
    </div>
  )
}

export default EliminarEvento
