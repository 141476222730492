import React, { useState, useMemo } from 'react';
import { Dialog, DialogPanel, TextInput } from '@tremor/react';
import { RRule } from 'rrule';
import { CREATE_EVENT, GET_EVENT } from '../../services/calendario/calendarioService';
import { useMutation } from '@apollo/client';
import Notification from '../shared/NotificationAlert';

const CalendarioForm = ({ isDialogOpen, setIsDialogOpen, selectedDate, handleEventCreated }) => {
    const [createEvent, { events }] = useMutation(CREATE_EVENT, {
        refetchQueries: [{ query: GET_EVENT }],
        onCompleted: () => {
            handleShowNotification(`Se creo el nuevo evento "${eventName}" con éxito .`)
            setIsDialogOpen(false);
        },
        onError: (error) => {
            console.error('Error al eliminar el evento:', error.message);
        }
    });

    const [eventName, setEventName] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [repeatFrequency, setRepeatFrequency] = useState('');

    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const daysOfWeek = useMemo(() => ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'], []);

    const handleDayChange = (day) => {
        setSelectedDays(prev => prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]);
    };

    const generateRRule = () => {
        const startDate = new Date(selectedDate);
        const currentYear = new Date().getFullYear();
        let rule;

        switch (repeatFrequency) {
        case 'once':
            rule = new RRule({
            freq: RRule.DAILY,
            interval: 1,
            dtstart: startDate,
            count: 1,
            until: new Date(currentYear, 11, 31)
            });
            break;

        case 'weekly':
            rule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: selectedDays.map(day => daysOfWeekToRRule(day)).filter(Boolean),
            dtstart: startDate,
            until: new Date(currentYear, 11, 31)
            });
            break;

        case 'biweekly':
            rule = new RRule({
            freq: RRule.WEEKLY,
            interval: 2,
            byweekday: selectedDays.map(day => daysOfWeekToRRule(day)).filter(Boolean),
            dtstart: startDate,
            until: new Date(currentYear, 11, 31)
            });
            break;

        case 'monthly':
            rule = new RRule({
            freq: RRule.MONTHLY,
            interval: 1,
            bymonthday: [startDate.getDate()],
            dtstart: startDate,
            until: new Date(currentYear, 11, 31)
            });
            break;

        case 'biannually':
            rule = new RRule({
            freq: RRule.MONTHLY,
            interval: 6,
            bymonthday: [startDate.getDate()],
            dtstart: startDate,
            until: new Date(currentYear, 11, 31)
            });
            break;

        case 'yearly':
            rule = new RRule({
            freq: RRule.YEARLY,
            interval: 1,
            bymonth: startDate.getMonth() + 1,
            bymonthday: startDate.getDate(),
            dtstart: startDate,
            until: new Date(currentYear, 11, 31)
            });
            break;

        default:
            console.error('Frecuencia no válida');
            return;
        }

        return rule.toString();
    };

    const daysOfWeekToRRule = (day) => {
        const dayMap = {
            'Lunes': RRule.MO,
            'Martes': RRule.TU,
            'Miércoles': RRule.WE,
            'Jueves': RRule.TH,
            'Viernes': RRule.FR,
            'Sábado': RRule.SA,
            'Domingo': RRule.SU,
        };
        return dayMap[day];
    };

    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    const handleCreateEvent = () => {
        const newEvent = {
            title: eventName,
            rrule: generateRRule(),
            start: new Date(selectedDate),
            end: new Date(selectedDate.getTime() + 60 * 60 * 1000),
        };

        createEvent({ 
            variables: { 
                events: newEvent
            } 
        }).then(result => {
            handleEventCreated(newEvent);
        }).catch(error => {
            console.log(errorMessage)
        });
    };

  return (
    <div>
        {showNotification && <Notification message={message} />}
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} static={true} className="z-[100]">
        <DialogPanel className="w-full p-4">
            <h2 className="text-lg font-semibold mb-2">Agregar evento</h2>
            
            <div className="mb-4">
            <label className="block mb-1">Nombre del evento</label>
            <TextInput
                type="text"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                className="w-full"
                placeholder="Ej. Visita ODMA"
            />
            </div>

            <div className="mb-4">
            <label className="block mb-1">Días de la semana</label>
            <div className="grid grid-cols-3 gap-2">
                {daysOfWeek.map((day, index) => (
                <label key={index} className="flex items-center">
                    <input
                    type="checkbox"
                    value={day}
                    checked={selectedDays.includes(day)}
                    onChange={() => handleDayChange(day)}
                    className="mr-2"
                    />
                    {day}
                </label>
                ))}
            </div>
            </div>

            <div className="mb-4">
            <label className="block mb-1">Frecuencia de repetición</label>
            <select
                value={repeatFrequency}
                onChange={(e) => setRepeatFrequency(e.target.value)}
                className="w-full border border-gray-300 rounded-lg"
            >
                <option value="">Selecciona una opción</option>
                <option value="once">Única vez</option>
                <option value="weekly">Cada semana</option>
                <option value="biweekly">Cada 15 días</option>
                <option value="monthly">Cada mes</option>
                <option value="biannually">Cada 6 meses</option>
                <option value="yearly">Cada año</option>
            </select>
            </div>

            <div className="flex justify-between mt-4 gap-2">
            <button onClick={handleCreateEvent} className="flex-1 bg-blue-500 text-white p-2 rounded">Agregar evento</button>
            </div>
        </DialogPanel>
        </Dialog>
    </div>
  );
};

export default CalendarioForm;
