import { Card, Text, Metric, Flex } from '@tremor/react'
import React from 'react'

const CardGridMap = ({data}) => {
    const friendlyNames = {
        aceite: 'Aceite',
        basura: 'Basura',
        agua: 'Agua',
        lodo: 'Lodo',
        porrones_tinas: 'Porrones/Tinas',
        filtro_de_aire: 'Filtro de Aire'
      };
      
    const colors = ['rose', 'stone', 'purple', 'yellow', 'blue'];
  
  return (
    <div className='flex gap-2 max-w-full overflow-x-auto pb-3 md:w-auto'>
        {data?.getQuantityCalculations?.byScrap.map ((scrap, index) =>(
            <Card key={index} className='flex p-2 gap-2 items-center justify-between static' decoration="top" decorationColor={colors[index % colors.length]}>
                <h1 >{friendlyNames[scrap._id]}</h1>
                <div className='flex gap-5 items-end'>
                    {scrap._id === 'basura' || scrap._id === 'lodo' ? (
                        <Metric className='flex items-center gap-1'>
                            {scrap.total.toLocaleString('en-US')} <Text>Kilos</Text>
                        </Metric>
                    ) : scrap._id === 'porrones_tinas' || scrap._id === 'filtro_de_aire' ? (
                        <Metric className='flex items-center gap-1'>
                            {scrap.total.toLocaleString('en-US')} <Text>Piezas</Text>
                        </Metric>
                    ) : (
                        <Metric className='flex items-center gap-1'>
                            {scrap.total.toLocaleString('en-US')} <Text>Litros</Text>
                        </Metric>
                    )}
                </div>
            </Card>
        ))}
    </div>
  )
}

export default CardGridMap
