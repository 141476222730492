import React, { useState, useMemo, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Card } from '@tremor/react';
import { RRule } from 'rrule';
import CalendarioForm from './CalendarioForm';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { GET_EVENT } from '../../services/calendario/calendarioService';
import { useQuery } from '@apollo/client';
import EliminarEvento from './EliminarEvento';

const localizer = momentLocalizer(moment);

const messages = {
  allDay: 'Todo el día',
  previous: 'Atrás',
  next: 'Siguiente',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  noEventsInRange: 'No hay eventos en este rango',
  showMore: (total) => `+ Ver más (${total})`,
};

// Función para generar ocurrencias de eventos desde una regla RRULE
const generateEventOccurrences = (event) => {
  if (!event.rrule) return [];

  const rule = RRule.fromString(event.rrule);
  const occurrences = rule.all();

  return occurrences.map(date => ({
    title: event.title,
    start: date,
    end: new Date(date.getTime() + 60 * 60 * 1000),
  }));
};

const Eventos = () => {
  const { loading, error, data, refetch } = useQuery(GET_EVENT);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [eventName, setEventName] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [visibleStartDate, setVisibleStartDate] = useState(new Date());
  const [visibleEndDate, setVisibleEndDate] = useState(new Date());
  const [cachedEvents, setCachedEvents] = useState([]);

  useEffect(() => {
    const today = new Date();
    setVisibleStartDate(moment(today).startOf('month').toDate());
    setVisibleEndDate(moment(today).endOf('month').toDate());
  }, []);

  // Efecto para almacenar los eventos en caché
  useEffect(() => {
    if (data?.getCalendarEvents && cachedEvents.length === 0) {
      const newEvents = data.getCalendarEvents.flatMap(event => generateEventOccurrences(event));
      setCachedEvents(newEvents);
    }
  }, [data?.getCalendarEvents]);

  const events = useMemo(() => {
    return cachedEvents.filter(event => {
      const start = new Date(event.start);
      return start >= visibleStartDate && start < visibleEndDate;
    });
  }, [cachedEvents, visibleStartDate, visibleEndDate]);

  if (loading && cachedEvents.length === 0) return <Card className='w-full mx-10 mt-10'>Cargando eventos...</Card>;
  if (error) return <p>Error al cargar eventos: {error.message}</p>;

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    setIsDialogOpen(true);
  };

  const handleSelectEvent = (event) => {
    setDeleteDialogOpen(true)
    setEventName(event.title)
  };

  const handleNavigate = (date) => {
    setVisibleStartDate(moment(date).startOf('month').toDate());
    setVisibleEndDate(moment(date).endOf('month').toDate());
  };

  const handleEventDeleted = () => {
    setCachedEvents((prevEvents) =>
        prevEvents.filter(event => event.title !== eventName)
    );
  };

  const handleEventCreated = (newEvent) => {
    const occurrences = generateEventOccurrences(newEvent);
    setCachedEvents((prevEvents) => [...prevEvents, ...occurrences]);
  };

  return (
    <div className="mx-6 md:mx-10 my-5">
      <Card>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          messages={messages}
          selectable={true}
          onSelectSlot={handleSelectSlot}
          onNavigate={handleNavigate}
          onSelectEvent={handleSelectEvent}
        />
        <CalendarioForm
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            selectedDate={selectedDate}
            handleEventCreated={handleEventCreated}
        />
        <EliminarEvento
            isDeleteDialogOpen={isDeleteDialogOpen}
            setDeleteDialogOpen={setDeleteDialogOpen}
            eventName={eventName}
            handleEventDeleted={handleEventDeleted} 
        />
      </Card>
    </div>
  );
};

export default Eventos;
