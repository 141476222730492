import { RiAddFill, RiMoneyDollarBoxLine } from '@remixicon/react';
import { Button, Card, Select, SelectItem, TextInput } from '@tremor/react'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { ADD_EXPENSE, GET_EXPENSE, GET_TYPE_EXPENSE } from '../../services/gastosFijos/gastosFijosServices';
import Notification from '../shared/NotificationAlert';

const CrearGasto = () => {
    const { loading, error, data, refetch } = useQuery(GET_TYPE_EXPENSE);
    const [addExpense, { dataExpense }] = useMutation(ADD_EXPENSE, {
      refetchQueries: [{ query: GET_EXPENSE }]
    });

    const [montoValue, setMontoValue] = useState('');
    const [nombreGastoValue, setNombreGastoValue] = useState('');
    const [tipoValue, setTipoValue] = useState('');

    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const handleMontoChange = (value) => {
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value)) {
            setMontoValue(value);
        }
    };
    const handleGastoChange = (value) => {
        setNombreGastoValue(value);
    };

    const handleShowNotification = (message) => {
      setMessage(message)
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    };

    const handleAddExpense = () =>{
      addExpense({ 
          variables: { 
              monto: parseFloat(montoValue),
              nombre: nombreGastoValue,
              tipo: tipoValue
          } 
      }).then(result => {
          handleShowNotification(`Se ingresó el nuevo gasto fijo "${nombreGastoValue}" en "${tipoValue}" con éxito .`)
          setMontoValue('')
          setNombreGastoValue('')
          setTipoValue('')
      }).catch(error => {
          const errorMessage = error.message;
          console.log(errorMessage)
          handleShowNotification(errorMessage, 'Oops falló algo!')
      });
  }

  return (
    <div className='flex'>
      {showNotification && <Notification message={message} />}
      <Card className='md:h-32'>
        <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mb-3">Gasto fijo</h3>
          <div className='flex flex-col md:flex-row gap-5'>
              <Select value={tipoValue} defaultValue="1" placeholder='Selecciona..' onValueChange={setTipoValue}>
                {data?.getBillTypes.map((types) => (
                  <SelectItem key={types.tipo} value={types.tipo}>{types.tipo}</SelectItem>
                ))}
              </Select>
              <TextInput 
                  value={nombreGastoValue} 
                  onValueChange={handleGastoChange} 
                  placeholder="Ingresa nombre del gasto.." 
              />
              <TextInput 
                  icon={RiMoneyDollarBoxLine}
                  value={montoValue} 
                  maxLength={8}
                  onValueChange={handleMontoChange} 
                  placeholder="Ingresa monto.." 
              />
              <Button 
                  onClick={() => handleAddExpense()} 
                  icon={RiAddFill}
                  disabled={!montoValue && !nombreGastoValue && !tipoValue}>
              </Button>
          </div>
      </Card>
    </div>
  )
}

export default CrearGasto
