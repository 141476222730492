import React from 'react'
import PageHeader from '../components/sidebar/PageHeader'
import CrearTipoGasto from '../components/gastos/CrearTipoGasto'
import CrearGasto from '../components/gastos/CrearGasto'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@tremor/react'
import { RiAddBoxLine, RiWallet3Line } from '@remixicon/react'
import GastosTable from '../components/gastos/GastosTable'
import TipoGastosTable from '../components/gastos/TipoGastosTable'

const GastosFijos = () => {
  return (
    <div className='w-full'>
        <PageHeader pageTitle={"Gastos Fijos"} />
        <div className="flex gap-5 m-6 md:mx-10">
            <div className='flex flex-col w-full md:w-9/12 gap-5'>
                <CrearTipoGasto/>
                <CrearGasto/>
                <GastosTable/>
            </div>
            <div className='hidden md:block w-full md:w-3/12'>
                <TipoGastosTable/>
            </div>
        </div>
    </div>
  )
}

export default GastosFijos
