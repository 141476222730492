import React, { useEffect, useState } from 'react'
import { Dialog, DialogPanel, Button, DatePicker, Table, TableHead, TableRow, TableHeaderCell, TableCell, TableBody, BadgeDelta, TextInput } from '@tremor/react'; // Importa los componentes necesarios de tu biblioteca de UI
import Notification from '../shared/NotificationAlert'
import { es } from 'date-fns/locale'
import moment from 'moment';
import { GET_BALANCE, GET_FINANCE, GET_FINANCE_TOTAL, UPDATE_FINANCE } from '../../services/finanzas/finanzasService';
import { useMutation } from '@apollo/client';
import { RiEdit2Line } from '@remixicon/react';
import { GET_RECORDS, UPDATE_RECORD } from '../../services/registros/registroService';
const EditFinanza = ({editData, isEditarOpen, setIsEditarOpen}) => {

    const [updateFinance, { data }] = useMutation(UPDATE_FINANCE, {
        refetchQueries: [
            { query: GET_FINANCE },
            { query: GET_BALANCE }, 
            { query: GET_FINANCE_TOTAL},
            { query: GET_RECORDS }
        ]
    });

    const [updateRecord, { dataRecord }] = useMutation(UPDATE_RECORD, {
        refetchQueries: [
            { query: GET_FINANCE },
            { query: GET_BALANCE }, 
            { query: GET_FINANCE_TOTAL},
            { query: GET_RECORDS }
        ]
    });

    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const [newEntryValue, setNewEntry] = useState(editData?.entry);
    const [newExitValue, setNewExit] = useState(editData?.exit);
    const [newEntryDate, setNewEntryDate] = useState(editData?.entry_date);
    const [type, setType] = useState(editData?.type || 'entrada');

    const handleEntryChange = (value) => {
        setNewEntry(value);
        setNewExit(0);
        setType('entrada'); 
      };
    
      const handleExitChange = (value) => {
        setNewExit(value);
        setNewEntry(0);
        setType('gasto');
      };

    const handleDateChange = (date) => {
        setNewEntryDate(date);
    };

    const formatDateTime = (timestamp) => {
        return moment.unix(timestamp).tz('America/Monterrey').format('DD/MM/YYYY');
    };

    const handleUpdate = (data) => {
        const updatedEntry = {
            entry: parseFloat(newEntryValue),
            exit: parseFloat(newExitValue),
            entry_date: newEntryDate,
            type: type
        };

        updateFinance({ 
            variables: { 
                id: data._id,
                data: {
                    entry: updatedEntry.entry,
                    exit: updatedEntry.exit,
                    entry_date: updatedEntry.entry_date,
                    type: type,
                }
            }})
        .then(result => {
            !data.record_id && (handleShowNotification(`El movimiento fue actualizado con éxito.`))
            
            if (data.record_id) {
                updateRecord({
                    variables: {
                        id: data.record_id,
                        data: {
                            type: type === 'gasto' ? 'exit' : 'entry',
                            scrap: data.scrap,
                            entry_date: updatedEntry.entry_date,
                            total: updatedEntry.entry > 0 ? updatedEntry.entry : -updatedEntry.exit
                        }
                    }
                })
                    .then(result => {
                    handleShowNotification(`El movimiento y registro fueron actualizados con éxito.`);
                })
                .catch(error => {
                    const errorMessage = error.message;
                    handleShowNotification(errorMessage);
                });
            }

        })
        .catch(error => {
            const errorMessage = error.message;
            handleShowNotification(errorMessage)
        });
        setIsEditarOpen(false)
      };

    // Actualiza el estado cuando editData cambie
    useEffect(() => {
        setNewEntry(editData?.entry);
        setNewExit(editData?.exit);
        setNewEntryDate(editData?.entry_date);
        setType(editData?.type);
    }, [editData]);

    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000); // Oculta la notificación después de 3 segundos
      };

  return (
    <div>
      <Dialog
        open={isEditarOpen}
        onClose={() => setIsEditarOpen(false)}
        static={true}
        className="z-[100]"
      >
        <DialogPanel className='max-w-6xl w-full'>
            <div className='flex flex-col md:flex-row md:items-center justify-between mb-4 gap-3 md:gap-0'>
                <h2 className="text-lg font-semibold">Editar movimiento</h2>
                <div className='flex md:justify-center items-center gap-5 mr-5'>
                    <p>Fecha: </p>
                    <DatePicker
                        value={newEntryDate ? new Date(newEntryDate * 1000) : null}
                        onValueChange={value => handleDateChange(moment(value).unix())}
                        className="w-32 mr-20"
                        lang='es'
                        locale={es}
                        maxDate={new Date()}
                        enableClear={false}
                    />
                </div>
            </div>
            <Table className='w-full py-12'>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Tipo</TableHeaderCell>
                        <TableHeaderCell>Empresa</TableHeaderCell>
                        <TableHeaderCell>Descripción</TableHeaderCell>
                        <TableHeaderCell>Residuo</TableHeaderCell>
                        <TableHeaderCell>Entrada</TableHeaderCell>
                        <TableHeaderCell>Salida</TableHeaderCell>
                        <TableHeaderCell>Acción</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {editData?.length === 0 ? (
                    <TableRow>
                    <TableCell colSpan={6} className="text-center">
                        No hay registros del día de hoy.
                    </TableCell>
                    </TableRow>
                ) : (
                    <TableRow key={editData?._id}>
                        <TableCell>
                          {type === 'pago' || type === "gasto" ? <BadgeDelta deltaType="decrease">Gasto</BadgeDelta> : <BadgeDelta deltaType="increase">Entrada</BadgeDelta>}
                        </TableCell>
                        <TableCell>{editData?.company.name}</TableCell>
                        <TableCell>{editData?.description}</TableCell>
                        <TableCell>{editData?.scrap}</TableCell>
                        <TableCell className='w-20'>
                            <TextInput className="w-20 p-1" placeholder="Ingresa el valor de entrada.." value={newEntryValue} onValueChange={handleEntryChange} />
                        </TableCell>
                        <TableCell className='w-20'>
                            <TextInput className="w-20 p-1" placeholder="Entrada.." value={newExitValue} onValueChange={handleExitChange} />
                        </TableCell>
                        <TableCell>
                            <Button
                            icon={RiEdit2Line}
                            className="flex items-center"
                            onClick={() => handleUpdate(editData)}
                            >
                            Actualizar
                            </Button>
                        </TableCell>
                    </TableRow>
                    )}
                </TableBody>
            </Table>
            <div className='flex gap-4 flex-col mt-10'>
                <Button
                    variant="light"
                    className="mx-auto flex items-center"
                    onClick={() => setIsEditarOpen(false)}
                >
                Cerrar
                </Button>
            </div>
        </DialogPanel>
      </Dialog>
    {showNotification && <Notification message={message} />}
    </div>
  )
}

export default EditFinanza
