import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useAuthTokens = () => {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [tokens, setTokens] = useState({ accessToken: null, idToken: null });

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          const storedIdToken = localStorage.getItem('idToken');
          if (storedIdToken) {
            setTokens({ accessToken, idToken: storedIdToken });
          } else {
            const idTokenClaims = await getIdTokenClaims();
            const idToken = idTokenClaims.__raw;
            setTokens({ accessToken, idToken });
            localStorage.setItem('idToken', idToken);
          }
        }
      } catch (error) {
        console.error('Error al obtener los tokens:', error);
      }
    };

    fetchTokens();
  }, [getAccessTokenSilently, getIdTokenClaims, isAuthenticated]);

  return tokens;
};

export default useAuthTokens;
