import { useMutation, useQuery } from '@apollo/client';
import { BadgeDelta, Button, Card, Select, SelectItem, Table, TableBody, TableCell, TableRow } from '@tremor/react';
import React, { useState, useEffect } from 'react';
import { ADD_MANIFIESTO_SALIDA, DELETE_MANIFIESTO, GET_MANIFIESTOS, UPDATE_MANIFIESTO_ENTRADA, UPDATE_MANIFIESTO_SALIDA } from '../../services/manifiestos/manifiestosService';
import moment from 'moment';
import ManifiestoTableHead from './ManifiestoTableHead';
import FullManifiestos from './FullManifiestos';
import Notification from '../shared/NotificationAlert';
import { RiArrowDownCircleFill, RiArrowDownSLine, RiArrowUpSFill, RiArrowUpSLine, RiCornerLeftDownLine, RiDeleteBin2Fill, RiEdit2Line, RiTableLine } from '@remixicon/react';
import ConfirmationModal from '../shared/ConfirmationModal';
import { es } from 'date-fns/locale'
import EditarManifiesto from './EditarManifiesto';
import ManifiestoRow from './ManifiestoRow';

const TablaManifiestos = ({residuoManifiesto}) => {
  const { loading, error, data } = useQuery(GET_MANIFIESTOS, {
    variables: { residuo: residuoManifiesto},
  });
  const useCustomMutation = (mutation, residuoManifiesto) => {
    return useMutation(mutation, {
      refetchQueries: [
        {
          query: GET_MANIFIESTOS,
          variables: { residuo: residuoManifiesto ? residuoManifiesto : "" },
        },
      ],
    });
  };
  const [addManifiestoSalida, { dataManifiestos }] = useCustomMutation(ADD_MANIFIESTO_SALIDA, residuoManifiesto);
  const [deleteManifiesto, { dataManifiesto }] = useCustomMutation(DELETE_MANIFIESTO, residuoManifiesto);
  const [updateManifiestoEntrada, { updatedEntradaManifiesto }] = useCustomMutation(UPDATE_MANIFIESTO_ENTRADA, residuoManifiesto);
  const [updateManifiestoSalida, { updatedSalidaManifiesto }] = useCustomMutation(UPDATE_MANIFIESTO_SALIDA, residuoManifiesto);
  
  //Notification values
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');
  // Estado para almacenar los índices de los manifiestos seleccionados
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [manifiestoIndex, setManifiestoIndex] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [newRowData, setNewRowData] = useState({
    fechaEntrada: '',
    noManifiesto: '',
    razonSocial: '',
    estado: '',
    residuo: '',
    toxico: '',
    inflamable: '',
    densidad: '',
    cantidadLts: '',
    manifiesto: '',
    fase: '',
    transportista: '',
    noAutorizacionTransportista: '',
    cantidadResiduo: '',
    destinoFinal: '',
    numeroManifiesto: '',
    noAutorizacionDestinoFinal: ''
  });
  //Notification values
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [messageModal, setModalMessage] = useState('');
  const [dataToMove, setDataToMove] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState(null);
  const [confirmAction, setConfirmAction] = useState('');
  const [selectedId, setSelectedId] = useState(null); // Nuevo estado para almacenar el ID seleccionado
  const [title, setTitle] = useState('');//Notification values
  const [isOpen, setIsOpen] = useState(false);
  const [isEditarOpen, setIsEditarOpen] = useState(false);
  const [editData, setEditData] = useState();

  const [tipoManifiesto, setTipoManifiesto] = useState('');
  const [tipoResiduo, setTipoResiduo] = useState('');

  // Estado para almacenar la fecha seleccionada
  const [selectedDate, setSelectedDate] = useState(new Date());

  const friendlyNames = {
    aceite: 'Aceite',
    basura: 'Basura',
    agua: 'Agua',
    lodo: 'Lodo',
    porrones_tinas: 'Porrones / Tinas',
    filtro_de_aire: 'Filtro de Aire'
  };

  // Función para manejar el cambio de fecha
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleRowClick = (manifiestoIndex) => {
    setManifiestoIndex(manifiestoIndex)
    setSelectedRowIndex((prevIndex) => (prevIndex === manifiestoIndex ? null : manifiestoIndex));
  };

  const handleNewRowChange = (newData) => {
    setNewRowData(newData);
  };

  const sumarCantidadLtsAnteriores = (manifiestos, currentIndex) => {
    let totalLts = 0;
    let previousLts = 0;
    let previousType = 'No hay registro anterior';
  
    if (currentIndex > -1 && currentIndex < manifiestos?.length) {
      for (let i = currentIndex; i >= 0; i--) {
        const currentItem = manifiestos[i];
        if (currentItem.tipo === 'SALIDA') {
          break;
        }
        previousLts = parseFloat(currentItem.cantidad_lts) || 0;
        totalLts += previousLts;
        previousType = currentItem.tipo;
  
        // Si el manifiesto tiene manifiestos_salida, procesarlos también
        if (currentItem.manifiestos_salida && currentItem.manifiestos_salida.length > 0) {
          for (let j = 0; j < currentItem.manifiestos_salida.length; j++) {
            const salidaItem = currentItem.manifiestos_salida[j];
            if (salidaItem.tipo === 'SALIDA') {
              break;
            }
            previousLts = parseFloat(salidaItem.cantidad_lts) || 0;
            totalLts += previousLts;
            previousType = salidaItem.tipo;
          }
        }
      }
    }
  
    return { 
      previousType: previousType, 
      previousLts: previousLts.toString(), 
      currentLts: currentIndex > -1 ? manifiestos[currentIndex]?.cantidad_lts : '0',
      totalLts: totalLts.toString()
    };
  };  
  
  const totalCantidadLts = sumarCantidadLtsAnteriores(data?.getManifiestos, manifiestoIndex);

  const handleAddManifiesto = () => {
    const manifiestosMismaFecha = data?.getManifiestos.filter(m => m.fecha_entrada === newRowData.fecha_entrada);

    const index = data?.getManifiestos.findIndex(m => m._id === newRowData._id);
    // Encontrar el último índice con datos en manifiestos_salida antes del índice especificado
    const lastIndexWithSalida = data.getManifiestos.slice(0, index).reduce((lastIndex, m, currentIndex) => {
      if (m.manifiestos_salida && m.manifiestos_salida.length > 0) {
        return currentIndex;
      }
      return lastIndex;
    }, -1);

    let startIndex = lastIndexWithSalida === -1 ? 0 : lastIndexWithSalida + 1;
    const manifiestosIdsAnteriores = data.getManifiestos.slice(startIndex, index).map(m => m._id);
    // Incluir el ID del índice actual
    manifiestosIdsAnteriores.push(newRowData._id);

    addManifiestoSalida({ 
      variables: { 
        id: newRowData._id,
        idData: {
          ids: manifiestosIdsAnteriores,
          destino: {
            destino_final: newRowData.destino_final,
            no_autorizacion_destino_final: newRowData.no_autorizacion_destino_final
          }
        },
        data: {
          residuo: newRowData.residuo,
            manifiestos_salida: [{
              fecha_entrada: moment(selectedDate).unix(),
              no_manifiesto: newRowData.no_manifiesto,
              razon_social: newRowData.razon_social,
              estado: newRowData.estado,
              residuo: newRowData.residuo,
              toxico: newRowData.toxico,
              inflamable: newRowData.inflamable,
              densidad: newRowData.densidad,
              cantidad_lts: newRowData.cantidad_lts,
              fase: newRowData.fase,
              transportista: newRowData.transportista,
              no_autorizacion_transportista: newRowData.no_autorizacion_transportista,
              cantidad_residuo: newRowData.cantidad_residuo,
              manifiesto: newRowData.manifiesto,
              destino_final: newRowData.destino_final,
              numero_manifiesto: newRowData.numero_manifiesto,
              no_autorizacion_destino_final: newRowData.no_autorizacion_destino_final,
              tipo: 'SALIDA'
            }]
        }
      } 
    }).then(result => {
        handleShowNotification(`Se creó el manifiesto  ${newRowData.no_manifiesto} con éxito.`)
        setSelectedRowIndex(null)

        // Obtener los manifiestos posteriores al newRowData._id
        const index = manifiestosMismaFecha.findIndex(m => m._id === newRowData._id);
        const manifiestosPosteriores = manifiestosMismaFecha.slice(index + 1);
        
        // Actualizar los manifiestos posteriores
        manifiestosPosteriores.forEach(m => {
          updateManifiestoEntrada({
            variables: {
              id: m._id,
              data: {
                residuo: m.residuo,
                orden: m.orden + 1 // Incrementar el orden en 1
              }
            }
          });
        });

    }).catch(error => {
        const errorMessage = error.message;
        console.log(errorMessage)
        handleShowNotification(errorMessage, 'Oops falló algo!')
      });
  };
   
  const handleEditManifiesto = (id, residuo, manifiesto) => {
    const manifiestoSalida = {
      id: id ? id : '',
      residuo: residuo ? residuo : '',
      manifiesto: manifiesto
    }
    setIsEditarOpen(true)
    setEditData(manifiestoSalida)
  }

  let handleManageOrden = (manifiesto, action) => {

    // Filtrar manifiestos
    let manifiestosMismaFecha = data?.getManifiestos.filter(m => m.fecha_entrada === manifiesto.fecha_entrada);
    const indexManifiestoActual = manifiestosMismaFecha.findIndex(m => m._id === manifiesto._id);
    const indexManifiestoSalida = manifiestosMismaFecha.findIndex(m => m.manifiestos_salida && m.manifiestos_salida.length > 0);

    // Constantes para mensajes de notificación
    const mensajes = {
      noManifiestoSalida: 'No se encontró un manifiesto de salida en la misma fecha',
      ultimoManifiesto: 'El manifiesto no puede moverse hacia abajo porque es el último de la lista de manifiestos con la misma fecha',
      noMoverArriba: 'No se puede mover hacia arriba',
      manifiestoAnteriorSalida: 'El manifiesto no puede moverse porque el manifiesto anterior también tiene datos en manifiestos de salida',
      errorActualizar: 'Error al actualizar el manifiesto'
    };

    if (indexManifiestoSalida === -1) {
      // No se encontró un manifiesto con manifiestos_salida
      handleShowNotification(mensajes.noManifiestoSalida);
      return;
    }

    // Verificación si el manifiesto actual tiene manifiestos_salida y es el último en la lista
    if (manifiesto.manifiestos_salida && manifiesto.manifiestos_salida.length > 0 && indexManifiestoActual === 0) {
      handleShowNotification(mensajes.ultimoManifiesto);
      return;
    }

    if (action === 'subir' && manifiesto.orden === 0) {
      handleShowNotification(mensajes.noMoverArriba);
      return;
    }
  
    let mensaje;
  
    if (action === 'subir') {
       let manifiestoConSalida = JSON.parse(JSON.stringify(manifiestosMismaFecha[indexManifiestoSalida]));
       let manifiestoActual = { ...manifiesto }; // copia el objeto ACTUAL

      manifiestoConSalida.manifiestos_salida[0].cantidad_lts = (
          parseFloat(manifiestoConSalida.manifiestos_salida[0].cantidad_lts) + parseFloat(manifiestoActual.cantidad_lts)
        ).toString();
      manifiestoConSalida.manifiestos_salida[0].cantidad_residuo = (
          parseFloat(manifiestoConSalida.manifiestos_salida[0].cantidad_residuo) + parseFloat(manifiestoActual.cantidad_residuo)
        ).toString();

        const manifiesto_id_actual = manifiestoActual._id;
        delete manifiestoActual._id;
        delete manifiestoActual.__typename;
        const manifiesto_id_con_salida = manifiestoConSalida._id;
        delete manifiestoConSalida._id;
        delete manifiestoConSalida.__typename;
        delete manifiestoConSalida.manifiestos_salida[0].__typename;
        manifiestoActual.orden -= 1;
        mensaje = `El manifiesto ${manifiestoActual.no_manifiesto} se movió hacia arriba`;

        updateManifiestoSalida({
          variables: {
            id: manifiesto_id_actual,
            data: manifiestoActual
          }
        }).then(() => {
          updateManifiestoSalida({
            variables: {
              id: manifiesto_id_con_salida,
              data: manifiestoConSalida
            }
          }).then(() => {
            handleShowNotification(mensaje);
          }).catch(error => {
            handleShowNotification(`Error al actualizar el manifiesto: ${error.message}`);
          });
        });
    } else if (action === 'bajar') {
      let manifiestoAnterior = {...manifiestosMismaFecha[indexManifiestoActual - 1]};
      let manifiestoActual = { ...manifiesto }; // copia el objeto ACTUAL

      manifiestoActual.orden += 1;
      const manifiesto_id_actual = manifiestoActual._id;
      delete manifiestoActual._id;
      delete manifiestoActual.__typename;
      mensaje = `El manifiesto ${manifiestoActual.no_manifiesto} se movió hacia abajo`;

      if(manifiestoActual?.manifiestos_salida.length > 0){
        if (manifiestoAnterior && (manifiestoAnterior?.manifiestos_salida.length > 0)) {
          handleShowNotification(mensajes.manifiestoAnteriorSalida);
          return;
        }

        manifiestoAnterior.manifiestos_salida = manifiestoActual.manifiestos_salida.map(item => ({...item}))
        manifiestoActual.manifiestos_salida = [];
        manifiestoAnterior.manifiestos_salida[0].cantidad_lts = (parseFloat(manifiestoAnterior.manifiestos_salida[0].cantidad_lts) - parseFloat(manifiestoActual.cantidad_lts)).toString();
        manifiestoAnterior.manifiestos_salida[0].cantidad_residuo = (parseFloat(manifiestoAnterior.manifiestos_salida[0].cantidad_residuo) - parseFloat(manifiestoActual.cantidad_residuo)).toString();
        
        const manifiesto_id_anterior = manifiestoAnterior._id;
        delete manifiestoAnterior._id;
        delete manifiestoAnterior.__typename;
        delete manifiestoAnterior.manifiestos_salida[0].__typename;

        
          updateManifiestoSalida({
            variables: {
              id: manifiesto_id_anterior,
              data: manifiestoAnterior
            }
          }).then(() => {
            handleShowNotification(mensaje);
          }).catch(error => {
            handleShowNotification(`Error al actualizar el manifiesto: ${error.message}`);
          });
      }
      updateManifiestoSalida({
        variables: {
          id: manifiesto_id_actual,
          data: manifiestoActual
        }
      }).then(() => {
        handleShowNotification(mensaje);
      }).catch(error => {
        handleShowNotification(`Error al actualizar el manifiesto: ${error.message}`);
      });

    }
  }

  const handleValueActionChange = (value, manifiesto, index) => {
    switch (value) {
      case 'subir':
        handleManageOrden(manifiesto, value);
        break;
      case 'bajar':
        handleManageOrden(manifiesto, value);
        break;
      case 'editar':
        handleEditManifiesto(null, manifiesto.residuo, manifiesto);
        break;
      case 'salida':
        manifiesto.tipo === 'SALIDA' ? null : handleRowClick(index)
        break;
      case 'eliminar':
        handleOpenConfirmModal(
          '¿Deseas eliminar este manifiesto?',
          'El manifiesto se eliminará permanentemente.',
          manifiesto._id,
          manifiesto.residuo,
          'ENTRADA',
          'delete',
          manifiesto
        );
        break;
      default:
        console.log(`Opción ${value} seleccionada.`);
    }
  };

  const deleteManifiestoById = (manifiestoId, manifiesto) => {

    const index = data?.getManifiestos.findIndex(m => m._id === manifiesto._id);
    // Encontrar el último índice con datos en manifiestos_salida antes del índice especificado
    const lastIndexWithSalida = data.getManifiestos.slice(0, index).reduce((lastIndex, m, currentIndex) => {
      if (m.manifiestos_salida && m.manifiestos_salida.length > 0) {
        return currentIndex;
      }
      return lastIndex;
    }, -1);

    let startIndex = lastIndexWithSalida === -1 ? 0 : lastIndexWithSalida + 1;
    const manifiestosIdsAnteriores = data.getManifiestos.slice(startIndex, index).map(m => m._id);
    // Incluir el ID del índice actual
    manifiestosIdsAnteriores.push(manifiesto._id);

    const manifiestosMismaFecha = data?.getManifiestos.filter(m => m.fecha_entrada === manifiesto.fecha_entrada);
    tipoManifiesto === 'SALIDA' ? 
    updateManifiestoSalida({ 
      variables: {
        id: manifiestoId, 
        idData: {
          ids: manifiestosIdsAnteriores,
          destino: {
            destino_final: "",
            no_autorizacion_destino_final: ""
          }
        },
        data: {
          residuo: tipoResiduo,
          manifiestos_salida: []
      }
    } 
  }).then(result => {
      handleShowNotification(`Se eliminó el manifiesto de salida ${manifiesto.no_manifiesto} con éxito.`)
      setSelectedRowIndex(null)

      const index = manifiestosMismaFecha.findIndex(m => m._id === manifiesto._id);
      const manifiestosPosteriores = manifiestosMismaFecha.slice(index + 1);
        
        // Actualizar los manifiestos posteriores
        manifiestosPosteriores.forEach(m => {
          updateManifiestoEntrada({
            variables: {
              id: m._id,
              data: {
                residuo: "aceite",
                orden: m.orden - 1 // Reduce el orden en 1
              }
            }
          });
        });

  }).catch(error => {
      const errorMessage = error.message;
      handleShowNotification(errorMessage)
      console.log(errorMessage)
  })
  :  deleteManifiesto({ variables: { id: manifiesto._id, residuo: tipoResiduo} })
      .then(result => {
        // Aquí puedes manejar el resultado si es necesario
        handleShowNotification(`El manifiesto fue eliminado con éxito.`)
      })
      .catch(error => {
        console.log(error)
        // Aquí puedes manejar errores si la mutación falla
        console.error('Error al eliminar manifiestgo:', error);
      });
  };

  // Función para verificar si un manifiesto por su índice está seleccionado
  const isManifiestoSelected = (index) => selectedIndexes.includes(index);
  const formatDateTime = (timestamp) => {
    return moment.unix(timestamp).tz('America/Monterrey').format('DD/MM/YYYY');;
  };

  const handleOpenConfirmModal = (title, message, id, tipo, tipoManifiesto, action, data) => {
    setTipoResiduo(tipo)
    setTipoManifiesto(tipoManifiesto);
    setModalMessage(message);
    setTitle(title);
    setSelectedId(id);
    setConfirmModalOpen(true);
    setSelectedRowIndex(null);
    setConfirmAction(action);
    setDataToMove(data);
    setDataToUpdate(data)
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
    setSelectedRowIndex(null);
  };
  const handleConfirmAction = () => {
    if (confirmAction === 'delete') {
      deleteManifiestoById(selectedId, dataToUpdate);
    }
    handleCloseConfirmModal();
  };
  const handleShowNotification = (message) => {
    setMessage(message)
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Oculta la notificación después de 3 segundos
  };

  const renderManifiestoRows = () => {
    return data?.getManifiestos.map((manifiesto, index) => (
      <React.Fragment key={index}>
        <TableRow
          className={`${isManifiestoSelected(index) ? 'bg-blue-200' : ''} ${manifiesto.tipo === 'ENTRADA' ? 'hover:bg-gray-100 cursor-pointer' : ''}`}
        >
          <TableCell className="flex gap-4 px-4 py-2">
              <Select
                placeholder={'Selecciona..'}
                value="default"
                onValueChange={(value) => handleValueActionChange(value, manifiesto, index)}
              >
              <SelectItem icon={RiArrowUpSLine} value="subir">Subir</SelectItem>
              <SelectItem icon={RiArrowDownSLine} value="bajar">Bajar</SelectItem>
              <SelectItem icon={RiEdit2Line} value="editar">Editar</SelectItem>
              <SelectItem icon={RiArrowDownCircleFill} value="salida">Manifiesto salida</SelectItem>
              <SelectItem icon={RiDeleteBin2Fill} value="eliminar">Eliminar</SelectItem>
            </Select>
          </TableCell>
          <TableCell className="px-4 py-2">{manifiesto.tipo === 'SALIDA' ? <BadgeDelta deltaType="decrease"></BadgeDelta> : <BadgeDelta deltaType="increase"></BadgeDelta>}</TableCell>
          <TableCell className="px-4 py-2">{ formatDateTime(manifiesto.fecha_entrada)}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.no_manifiesto}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.razon_social}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.estado}</TableCell>
          <TableCell className="px-4 py-2">{friendlyNames[manifiesto.residuo]}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.toxico}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.inflamable}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.densidad}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_lts}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.manifiesto}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.fase}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.transportista}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.no_autorizacion_transportista}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_residuo}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.destino_final}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.numero_manifiesto}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.no_autorizacion_destino_final}</TableCell>
        </TableRow>
        {(selectedRowIndex === index && manifiesto.tipo === 'ENTRADA') && (
          <ManifiestoRow
              tipo={'agregar'} 
              manifiesto={manifiesto}
              handleDateChange={handleDateChange}
              handleNewRowChange={handleNewRowChange}
              handleAddManifiesto={handleAddManifiesto}
              newRowData={newRowData}
              totalCantidadLts={totalCantidadLts}
          />
        )}
        {manifiesto.manifiestos_salida && manifiesto.manifiestos_salida.map((salida, salidaIndex) => (
            <TableRow
            key={`${index}-${salidaIndex}`}
              className="bg-gray-100"
            >
              <TableCell className="flex gap-1 p-4">
                <Button 
                  className='bg-red-600 border-transparent hover:border-transparent hover:bg-red-700 w-1/2' 
                  icon={RiDeleteBin2Fill}
                  onClick={() => handleOpenConfirmModal('¿Deseas eliminar este manifiesto de salida?', 'El manifiesto se eliminará permanentemente.', manifiesto._id, salida.residuo, 'SALIDA', 'delete', manifiesto)}
                ></Button>
                <Button 
                  className='w-1/2'
                  icon={RiEdit2Line}
                  onClick={() => handleEditManifiesto(manifiesto._id, manifiesto.residuo, salida)}
                ></Button>
              </TableCell>
              <TableCell className="px-4 py-2"><BadgeDelta deltaType="decrease"></BadgeDelta></TableCell>
              <TableCell className="px-4 py-2">{formatDateTime(salida.fecha_entrada)}</TableCell>
              <TableCell className="px-4 py-2">{salida.no_manifiesto}</TableCell>
              <TableCell className="px-4 py-2">{salida.razon_social}</TableCell>
              <TableCell className="px-4 py-2">{salida.estado}</TableCell>
              <TableCell className="px-4 py-2">{friendlyNames[salida.residuo]}</TableCell>
              <TableCell className="px-4 py-2">{salida.toxico}</TableCell>
              <TableCell className="px-4 py-2">{salida.inflamable}</TableCell>
              <TableCell className="px-4 py-2">{salida.densidad}</TableCell>
              <TableCell className="px-4 py-2">{salida.cantidad_lts}</TableCell>
              <TableCell className="px-4 py-2">{salida.manifiesto}</TableCell>
              <TableCell className="px-4 py-2">{salida.fase}</TableCell>
              <TableCell className="px-4 py-2">{salida.transportista}</TableCell>
              <TableCell className="px-4 py-2">{salida.no_autorizacion_transportista}</TableCell>
              <TableCell className="px-4 py-2">{salida.cantidad_residuo}</TableCell>
              <TableCell className="px-4 py-2">{salida.destino_final}</TableCell>
              <TableCell className="px-4 py-2">{salida.numero_manifiesto}</TableCell>
              <TableCell className="px-4 py-2">{salida.no_autorizacion_destino_final}</TableCell>
            </TableRow>
          ))}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <div className='flex gap-10'>
        <Button
          icon={RiTableLine}
          className="flex items-center w-full md:w-auto justify-center rounded-tremor-small border border-tremor-border bg-tremor-background px-2.5 py-2 text-tremor-default font-medium text-tremor-content-strong shadow-tremor-input hover:bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:text-dark-tremor-content-strong dark:shadow-dark-tremor-input hover:dark:bg-dark-tremor-background-muted"
          onClick={() => setIsOpen(true)}
        >
          Tabla completa
        </Button>
      </div>
      
      <Card className="overflow-x-auto mt-3 w-full">
        <h2 className="text-lg font-semibold mb-4">Tabla de Manifiestos</h2>
        <div className="overflow-x-auto">
          <Table className="table-auto min-w-full">
            <ManifiestoTableHead />
            <TableBody className="text-xs">
              {renderManifiestoRows()}
            </TableBody>
          </Table>
        </div>
      </Card>

      <FullManifiestos
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
      />
      <EditarManifiesto
        isEditarOpen={isEditarOpen}
        setIsEditarOpen={setIsEditarOpen}
        editData={editData?.manifiesto}
        idManifiestoEntrada={editData?.id}
        residuoManifiestoEntrada={editData?.residuo}
        totalCantidadLts={totalCantidadLts}
        residuoManifiesto={editData?.residuo}
      />
      <ConfirmationModal
        isOpen={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        message={messageModal}
        onConfirm={handleConfirmAction}
        onCancel={handleCloseConfirmModal}
        confirmText="Aceptar"
        cancelText="Cancelar"
        title={title}
        id={selectedId}
      />
      {showNotification && <Notification message={message} />}
    </div>
  );
};

export default TablaManifiestos;
