import { Button, Dialog, DialogPanel, Title } from '@tremor/react';
import React from 'react';

function ConfirmationModal({ isOpen, onClose, title, message, onConfirm, onCancel, confirmText, cancelText, id = null}) {
  return (
    <Dialog open={isOpen} onClose={onClose} static={true}>
      <DialogPanel>
        <Title>{title}</Title>
        <p className="mt-2 leading-6 text-gray-700">{message}</p>
        {onConfirm && (
          <Button className="mt-8 mr-2" onClick={() => onConfirm(id)}>
            {confirmText || 'Aceptar'}
          </Button>
        )}
        {onCancel && (
          <Button className="mt-8 bg-red-500 border-red-500 hover:bg-red-600 hover:border-transparent" onClick={onCancel}>
            {cancelText || 'Cancelar'}
          </Button>
        )}
      </DialogPanel>
    </Dialog>
  );
}

export default ConfirmationModal;
