import React from 'react'
import PageHeader from '../components/sidebar/PageHeader';
import CrearFinanza from '../components/finanzas/CrearFinanza';

const Home = () => {

  return (
    <div className='w-full'>
      <PageHeader pageTitle="Finanzas" />
      <CrearFinanza />
    </div>
  )
}

export default Home
