import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';''
import { RiAddFill, RiDeleteBin2Fill } from '@remixicon/react';
import { Button, Card, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@tremor/react';
import Notification from '../shared/NotificationAlert';
import ConfirmationModal from '../shared/ConfirmationModal';
import { DELETE_TYPE_EXPENSE, GET_TYPE_EXPENSE } from '../../services/gastosFijos/gastosFijosServices';

const TipoGastosTable = () => {
    const { loading, error, data, refetch } = useQuery(GET_TYPE_EXPENSE);
    const [deleteTypeExpense, { idType }] = useMutation(DELETE_TYPE_EXPENSE, {
        refetchQueries: [{ query: GET_TYPE_EXPENSE }]
      });
    
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [title, setTitle] = useState('');

    
    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      };

    const handleOpenConfirmModal = (title, message, id) => {
        setMessage(message)
        setTitle(title)
        setSelectedId(id);
        setConfirmModalOpen(true)
    };

    const handleCloseConfirmModal = () => {
        setConfirmModalOpen(false);
    };

    const handleConfirmAction = (id) => {
        // Lógica para la acción de confirmación
        deleteTypeById(id)
        handleCloseConfirmModal();
    };

    const deleteTypeById = (typeId) => {
        deleteTypeExpense({ variables: { id: typeId} })
            .then(result => {
            // Aquí puedes manejar el resultado si es necesario
            handleShowNotification(`El tipo de gasto fijo fue eliminado con éxito.`)
        })
            .catch(error => {
            // Aquí puedes manejar errores si la mutación falla
            console.error('Error al eliminar el tipo de gasto fijo:', error);
        });
    };
  return (
    <div className='w-full'>
        <ConfirmationModal
            isOpen={confirmModalOpen}
            onClose={handleCloseConfirmModal}
            message={message}
            onConfirm={handleConfirmAction}
            onCancel={handleCloseConfirmModal}
            confirmText="Aceptar"
            cancelText="Cancelar"
            title={title}
            id={selectedId}
        />
        {showNotification && <Notification message={message} />}
        <Card className='w-full'>
            <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mb-3">Tipos de gastos fijos</h3>
            <Table>
                <TableHead>
                    <TableRow>
                    <TableHeaderCell>Tipo</TableHeaderCell>
                    <TableHeaderCell>Eliminar</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.getBillTypes.length > 0 ? (
                        data.getBillTypes.map((types) => (
                        <TableRow key={types.tipo}>
                            <TableCell>{types.tipo}</TableCell>
                            <TableCell className="w-20">
                            <Button
                                className="bg-red-600 border-transparent hover:border-transparent hover:bg-red-700"
                                icon={RiDeleteBin2Fill}
                                onClick={() =>
                                handleOpenConfirmModal(
                                    '¿Deseas eliminar este tipo de gasto fijo?',
                                    'El tipo de gasto fijo se eliminará permanentemente.',
                                    types._id
                                )
                                }
                            ></Button>
                            </TableCell>
                        </TableRow>
                        ))
                    ) : (
                        <TableRow>
                        <TableCell colSpan={2} align="center">
                            No hay tipo de gastos fijos por mostrar
                        </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    </div>
  )
}

export default TipoGastosTable
