import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import RoutesApp from './routers/routes';
import { AuthProvider } from './services/AuthProvider';
import AuthenticatedApolloProvider from './services/AuthenticatedApolloProvider';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <AuthProvider>
        <AuthenticatedApolloProvider>
          <RoutesApp />
        </AuthenticatedApolloProvider>
      </AuthProvider>
    </Auth0Provider>
  </BrowserRouter>
);
