import { RiAddFill } from '@remixicon/react';
import { Button, Card, TextInput } from '@tremor/react'
import React, { useState } from 'react'

import { ADD_TYPE_EXPENSE, GET_TYPE_EXPENSE } from '../../services/gastosFijos/gastosFijosServices';
import { useMutation, useQuery } from '@apollo/client';
import Notification from '../shared/NotificationAlert';


const CrearTipoGasto = () => {
    const { loading, error, data, refetch } = useQuery(GET_TYPE_EXPENSE);
    const [addTypeExpense, { dataType }] = useMutation(ADD_TYPE_EXPENSE, {
        refetchQueries: [{ query: GET_TYPE_EXPENSE }]
      });

    const [tipoGastoValue, setTipoGastoValue] = useState('');
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    
    const handleTipoGastoChange = (value) => {
        setTipoGastoValue(value)
    };

    const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
    };

    const handleAddTypeExpense = () =>{
        addTypeExpense({ 
            variables: { 
                tipo: tipoGastoValue
            } 
        }).then(result => {
            handleShowNotification(`Se ingresó el nuevo tipo de gasto fijo "${tipoGastoValue}" con éxito.`)
            setTipoGastoValue('')
        }).catch(error => {
            const errorMessage = error.message;
            console.log(errorMessage)
            handleShowNotification(errorMessage, 'Oops falló algo!')
        });
    }

  return (
    <div className='flex flex-col gap-5'>
        {showNotification && <Notification message={message} />}
        <Card className='md:w-full h-32'>
            <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mb-3">Ingresar tipo de gasto fijo</h3>
            <div className='flex md:w-full gap-5'>
                <TextInput 
                    value={tipoGastoValue} 
                    onValueChange={handleTipoGastoChange} 
                    placeholder="Ingresa tipo de gasto.." 
                />
                <Button 
                    onClick={() => handleAddTypeExpense()} 
                    icon={RiAddFill}
                    disabled={!tipoGastoValue}>
                </Button>
            </div>
        </Card>
        
    </div>
  )
}

export default CrearTipoGasto
