import React from 'react';
import { Dialog, DialogPanel, Table, TableBody, TableHead, TableRow, TableCell, TableHeaderCell, Button } from '@tremor/react'; // Importa los componentes necesarios de tu biblioteca de UI
import moment from 'moment';
import { RiDownload2Fill } from '@remixicon/react';
import { CSVLink } from "react-csv";


const FullManifiestos = ({ isOpen, setIsOpen, data }) => {
  const friendlyNames = {
    aceite: 'Aceite',
    basura: 'Basura',
    agua: 'Agua',
    lodo: 'Lodo',
    porrones_tinas: 'Porrones / Tinas',
    filtro_de_aire: 'Filtro de Aire'
  };
  const formatDateTime = (timestamp) => {
    return moment.unix(timestamp).tz('America/Monterrey').format('DD/MM/YYYY');
    };

    const getCSVData = () => {
      const csvData = [];
    
      data?.getManifiestos.forEach((manifiesto) => {
        csvData.push({
          "Fecha de entrada": formatDateTime(manifiesto.fecha_entrada),
          "No. de manifiesto": manifiesto.no_manifiesto,
          "Razón Social": manifiesto.razon_social,
          "Estado": manifiesto.estado,
          "Registro SEMARNAT": manifiesto.registro_semarnat,
          "Residuo": manifiesto.residuo,
          "Corrosivo": manifiesto.corrosivo,
          "Reactivo": manifiesto.reactivo,
          "Explosivo": manifiesto.explosivo,
          "Tóxico": manifiesto.toxico,
          "Inflamable": manifiesto.inflamable,
          "Biológico Infeccioso": manifiesto.biologico_infeccioso,
          "Clave": manifiesto.clave,
          "Densidad": manifiesto.densidad,
          "Cantidad (Lts)": manifiesto.cantidad_lts,
          "Factor (Lts)": manifiesto.factor_lts,
          "Cantidad (Kg)": manifiesto.cantidad_kg,
          "Factor (Kg)": manifiesto.factor_kg,
          "Descripción": manifiesto.descripcion,
          "Manifiesto": manifiesto.manifiesto,
          "Cantidad (Litros)": manifiesto.cantidad_litros,
          "Cantidad (Ton)": manifiesto.cantidad_ton,
          "Cantidad (m³)": manifiesto.cantidad_m3,
          "Piezas": manifiesto.piezas,
          "CVE Mes": manifiesto.cve_mes,
          "Fase": manifiesto.fase,
          "Transportista": manifiesto.transportista,
          "No. de autorización (Transportista)": manifiesto.no_autorizacion_transportista,
          "Cantidad Residuo": manifiesto.cantidad_residuo,
          "Destino Final": manifiesto.destino_final,
          "No. Manifiesto": manifiesto.numero_manifiesto,
          "No. de autorización (Destino Final)": manifiesto.no_autorizacion_destino_final,
        });

        if (manifiesto.manifiestos_salida) {
          manifiesto.manifiestos_salida.forEach((salida) => {
            csvData.push({
              "Fecha de entrada": formatDateTime(salida.fecha_entrada),
              "No. de manifiesto": salida.no_manifiesto,
              "Razón Social": salida.razon_social,
              "Estado": salida.estado,
              "Registro SEMARNAT": salida.registro_semarnat,
              "Residuo": salida.residuo,
              "Corrosivo": salida.corrosivo,
              "Reactivo": salida.reactivo,
              "Explosivo": salida.explosivo,
              "Tóxico": salida.toxico,
              "Inflamable": salida.inflamable,
              "Biológico Infeccioso": salida.biologico_infeccioso,
              "Clave": salida.clave,
              "Densidad": salida.densidad,
              "Cantidad (Lts)": salida.cantidad_lts,
              "Factor (Lts)": salida.factor_lts,
              "Cantidad (Kg)": salida.cantidad_kg,
              "Factor (Kg)": salida.factor_kg,
              "Descripción": salida.descripcion,
              "Manifiesto": salida.manifiesto,
              "Cantidad (Litros)": salida.cantidad_litros,
              "Cantidad (Ton)": salida.cantidad_ton,
              "Cantidad (m³)": salida.cantidad_m3,
              "Piezas": salida.piezas,
              "CVE Mes": salida.cve_mes,
              "Fase": salida.fase,
              "Transportista": salida.transportista,
              "No. de autorización (Transportista)": salida.no_autorizacion_transportista,
              "Cantidad Residuo": salida.cantidad_residuo,
              "Destino Final": salida.destino_final,
              "No. Manifiesto": salida.numero_manifiesto,
              "No. de autorización (Destino Final)": salida.no_autorizacion_destino_final,
            });
          });
        }
      });
      return csvData;
    };
    
  // Renderizar las filas de la tabla de manifiestos
  const renderManifiestoFullRows = () => {
    return data?.getManifiestos.map((manifiesto, index) => (
      <React.Fragment  key={index}>
        <TableRow>
          <TableCell className="px-4 py-2">{ formatDateTime(manifiesto.fecha_entrada)}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.no_manifiesto}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.razon_social}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.estado}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.registro_semarnat}</TableCell>
          <TableCell className="px-4 py-2">{friendlyNames[manifiesto.residuo]}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.corrosivo}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.reactivo}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.explosivo}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.toxico}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.inflamable}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.biologico_infeccioso}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.clave}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.densidad}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_lts}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.factor_lts}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_kg}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.factor_kg}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.descripcion}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.manifiesto}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_litros}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_ton}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_m3}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.piezas}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cve_mes}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.fase}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.transportista}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.no_autorizacion_transportista}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.cantidad_residuo}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.destino_final}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.numero_manifiesto}</TableCell>
          <TableCell className="px-4 py-2">{manifiesto.no_autorizacion_destino_final}</TableCell>
        </TableRow>
        {manifiesto.manifiestos_salida && manifiesto.manifiestos_salida.map((salida, salidaIndex) => (
          <TableRow key={`${index}-${salidaIndex}`}>
            <TableCell className="px-4 py-2">{formatDateTime(salida.fecha_entrada)}</TableCell>
            <TableCell className="px-4 py-2">{salida.no_manifiesto}</TableCell>
            <TableCell className="px-4 py-2">{salida.razon_social}</TableCell>
            <TableCell className="px-4 py-2">{salida.estado}</TableCell>
            <TableCell className="px-4 py-2">{salida.registro_semarnat}</TableCell>
            <TableCell className="px-4 py-2">{friendlyNames[salida.residuo]}</TableCell>
            <TableCell className="px-4 py-2">{salida.corrosivo}</TableCell>
            <TableCell className="px-4 py-2">{salida.reactivo}</TableCell>
            <TableCell className="px-4 py-2">{salida.explosivo}</TableCell>
            <TableCell className="px-4 py-2">{salida.toxico}</TableCell>
            <TableCell className="px-4 py-2">{salida.inflamable}</TableCell>
            <TableCell className="px-4 py-2">{salida.biologico_infeccioso}</TableCell>
            <TableCell className="px-4 py-2">{salida.clave}</TableCell>
            <TableCell className="px-4 py-2">{salida.densidad}</TableCell>
            <TableCell className="px-4 py-2">{salida.cantidad_lts}</TableCell>
            <TableCell className="px-4 py-2">{salida.factor_lts}</TableCell>
            <TableCell className="px-4 py-2">{salida.cantidad_kg}</TableCell>
            <TableCell className="px-4 py-2">{salida.factor_kg}</TableCell>
            <TableCell className="px-4 py-2">{salida.descripcion}</TableCell>
            <TableCell className="px-4 py-2">{salida.manifiesto}</TableCell>
            <TableCell className="px-4 py-2">{salida.cantidad_litros}</TableCell>
            <TableCell className="px-4 py-2">{salida.cantidad_ton}</TableCell>
            <TableCell className="px-4 py-2">{salida.cantidad_m3}</TableCell>
            <TableCell className="px-4 py-2">{salida.piezas}</TableCell>
            <TableCell className="px-4 py-2">{salida.cve_mes}</TableCell>
            <TableCell className="px-4 py-2">{salida.fase}</TableCell>
            <TableCell className="px-4 py-2">{salida.transportista}</TableCell>
            <TableCell className="px-4 py-2">{salida.no_autorizacion_transportista}</TableCell>
            <TableCell className="px-4 py-2">{salida.cantidad_residuo}</TableCell>
            <TableCell className="px-4 py-2">{salida.destino_final}</TableCell>
            <TableCell className="px-4 py-2">{salida.numero_manifiesto}</TableCell>
            <TableCell className="px-4 py-2">{salida.no_autorizacion_destino_final}</TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      static={true}
      className="z-[100]"
    >
      <DialogPanel className="max-w-full">
        <div className="overflow-x-auto mt-6 w-full">
          <div className='flex items-center justify-between mb-4'>
            <h2 className="text-lg font-semibold">Tabla de Manifiestos</h2>
            <Button variant="secondary" className="flex items-center" icon={RiDownload2Fill}>
              <CSVLink data={getCSVData()} filename={"manifiestos.csv"} className="text-blue-500 mr-2">
                Descargar
              </CSVLink>
            </Button>
          </div>
          <Table className="table-auto">
            <TableHead>
              <TableRow className='text-xs'>
                  <TableHeaderCell className="px-4 py-2">Fecha de entrada</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">No. de manifiesto</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Razón Social</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Estado</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Registro SEMARNAT</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Residuo</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Corrosivo</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Reactivo</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Explosivo</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Tóxico</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Inflamable</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Biológico Infeccioso</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Clave</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Densidad</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Cantidad (Lts)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Factor (Lts)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Cantidad (Kg)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Factor (Kg)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Descripción</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Manifiesto</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Cantidad (Litros)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Cantidad (Ton)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Cantidad (m³)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Piezas</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">CVE Mes</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Fase</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Transportista</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">No. de autorización (Transportista)</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Cantidad Residuo</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">Destino Final</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">No. Manifiesto</TableHeaderCell>
                  <TableHeaderCell className="px-4 py-2">No. de autorización (Destino Final)</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody className='text-xs overflow-x-auto'>
              {renderManifiestoFullRows()}
            </TableBody>
          </Table>
        </div>
        <Button
          variant="light"
          className="mx-auto flex items-center"
          onClick={() => setIsOpen(false)}
        >
          Cerrar
        </Button>
      </DialogPanel>
    </Dialog>
  );
};

export default FullManifiestos;
