import { gql } from '@apollo/client';

export const  GET_USER_LIST = gql`
query Query {
  listUsers {
    users {
      _id
      email
      name
      role
    }
    pagination {
      items
      page
      pages
    }
  }
}
`;

export const ADD_USER = gql`
mutation Mutation($name: String!, $email: String!, $role: UserRoles!) {
  createUser(name: $name, email: $email, role: $role) {
    email
    name
    role
  }
}
`;

export const DELETE_USER = gql`
mutation Mutation($id: ID!) {
  deleteUser(_id: $id) {
    _id
  }
}
`;