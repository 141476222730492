import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, DatePicker, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput, Dialog, DialogPanel } from '@tremor/react';
import React, { useState } from 'react'
import { ADD_BILL, DELETE_EXPENSE, GET_BILLS, GET_EXPENSE, UPDATE_BILL } from '../../services/gastosFijos/gastosFijosServices';
import { RiDeleteBin2Fill, RiMoneyDollarBoxLine } from '@remixicon/react';
import moment from 'moment';
import { es } from 'date-fns/locale'
import Notification from '../shared/NotificationAlert';
import ConfirmationModal from '../shared/ConfirmationModal';

const GastosTable = () => {
    const { loading, error, data, refetch } = useQuery(GET_EXPENSE);
    const [deleteExpense, { idType }] = useMutation(DELETE_EXPENSE, {
        refetchQueries: [{ query: GET_EXPENSE }]
      });
    const [updateBill, { idUpdatBill }] = useMutation(UPDATE_BILL, {
        refetchQueries: [{ query: GET_EXPENSE }]
      });
    const [addBill, { idAddBills }] = useMutation(ADD_BILL, {
        refetchQueries: [{ query: GET_BILLS }]
      });

    const [montoValue, setMontoValue] = useState('');

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [currentBillId, setCurrentBillId] = useState(null);
    const [billDates, setBillDates] = useState({});
    const [paidState, setPaidState] = useState(false);

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [title, setTitle] = useState('');

  const agruparPorTipo = (data) => {
    return data.reduce((result, bill) => {
      if (!result[bill.tipo]) {
        result[bill.tipo] = [];
      }
      result[bill.tipo].push(bill);
      return result;
    }, {});
  };

  const handleShowNotification = (message) => {
    setMessage(message)
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleOpenConfirmModal = (title, message, id) => {
    setMessage(message)
    setTitle(title)
    setSelectedId(id);
    setConfirmModalOpen(true)
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const handleConfirmAction = (id) => {
    deleteExpenseById(id)
    handleCloseConfirmModal();
  };

  const handleMontoChange = (id, value) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setMontoValue((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };
 
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSaveDate = () => {
    if (currentBillId) {
      setBillDates((prevDates) => ({
        ...prevDates,
        [currentBillId]: selectedDate,
      }));
    }
    setIsDialogOpen(false);
  };

  const handlePagar = (bills) => {
      const billsToPay = bills.map(bill => ({
        ...bill,
        fecha_pago: moment(bill.fecha_pago, 'DD/MM/YYYY').unix(),
      }));
      addBill({variables: { bills: billsToPay },})
      .then(result => {
        handleShowNotification(`Los gastos fijos de "${billsToPay[0].tipo}" fue agregados con éxito.`)
      })
        .catch(error => {
        console.error('Error al actualizar el gasto fijo:', error);
      });
    
  };

  const handleUpdateBill = (id, nombre) => {
    const updatedMonto = parseFloat(montoValue[id]);
    if (!isNaN(updatedMonto)) {
      updateBill({variables: { id, monto: updatedMonto},})
      .then(result => {
        handleShowNotification(`El gasto fijo "${nombre}" fue actualizado con éxito.`)
      })
        .catch(error => {
        console.error('Error al actualizar el gasto fijo:', error);
      });
    }
  };

  const deleteExpenseById = (expenseId) => {
    deleteExpense({ variables: { id: expenseId} })
        .then(result => {
        handleShowNotification(`El gasto fijo fue eliminado con éxito.`)
    })
        .catch(error => {
        console.error('Error al eliminar el gasto fijo:', error);
    });
  };

  const groupedData = agruparPorTipo(data?.getBills || []);

  return (
    <div className="flex flex-wrap justify-center lg:gap-3 w-full gap-5">
      <ConfirmationModal
            isOpen={confirmModalOpen}
            onClose={handleCloseConfirmModal}
            message={message}
            onConfirm={handleConfirmAction}
            onCancel={handleCloseConfirmModal}
            confirmText="Aceptar"
            cancelText="Cancelar"
            title={title}
            id={selectedId}
        />
        {showNotification && <Notification message={message} />}
      {Object.keys(groupedData).length > 0 ? (
        Object.keys(groupedData).map((tipo) => (
          <Card key={tipo} className="flex flex-col lg:w-[49%] md:w-full sm:w-full justify-between">
            <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mb-3">
              {tipo}
            </h3>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Nombre</TableHeaderCell>
                  <TableHeaderCell>Monto</TableHeaderCell>
                  <TableHeaderCell>Fecha</TableHeaderCell>
                  <TableHeaderCell>Eliminar</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedData[tipo].map((bill) => (
                  <TableRow key={bill.nombre}>
                    <TableCell>{bill.nombre}</TableCell>
                    <TableCell>
                      <TextInput
                        icon={RiMoneyDollarBoxLine}
                        value={montoValue[bill._id] !== undefined ? montoValue[bill._id] : bill.monto.toLocaleString('en-US')}
                        maxLength={8}
                        onValueChange={(value) => handleMontoChange(bill._id, value)} 
                        onFocus={() => setMontoValue((prev) => ({ ...prev, [bill._id]: bill.monto }))}
                        onBlur={() => {
                          const updatedMonto = parseFloat(montoValue[bill._id]);
                          if (!isNaN(updatedMonto) && updatedMonto !== bill.monto) {
                            handleUpdateBill(bill._id, bill.nombre);
                          } else {
                            setMontoValue((prev) => ({ ...prev, [bill._id]: bill.monto }));
                          }
                        }}
                        placeholder="Ingresa monto.." 
                      />
                    </TableCell>
                    <TableCell 
                      className='cursor-pointer'
                      onClick={() => {
                        setCurrentBillId(bill._id);
                        setSelectedDate(billDates[bill._id] || new Date());
                        setIsDialogOpen(true);
                      }}>
                        
                      <span className="text-blue-600 cursor-pointer underline">
                        {moment(billDates[bill._id] || new Date()).format('DD/MM/YYYY')}
                      </span>
                    </TableCell>
                    <TableCell className="w-20">
                      <Button
                        className="bg-red-600 border-transparent hover:border-transparent hover:bg-red-700"
                        icon={RiDeleteBin2Fill}
                        onClick={() =>
                          handleOpenConfirmModal(
                            '¿Deseas eliminar este gasto fijo?',
                            'El gasto fijo se eliminará permanentemente.',
                            bill._id
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="flex md:justify-end mt-3 md:mt-0">
              <Button
                className="w-full md:w-1/2"
                onClick={() => {
                  const billsToPay = groupedData[tipo].map(bill => ({
                    nombre: bill.nombre,
                    monto: bill.monto,
                    tipo,
                    fecha_pago: moment(billDates[bill._id] || new Date()).format('DD/MM/YYYY')
                  }));
                  handlePagar(billsToPay);
                }}
              >
                Pagar
              </Button>
            </div>
          </Card>
        ))
      ) : (
        <Card className="w-full">
          <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium mb-3">
            Gastos fijos
          </h3>
          <p>No hay gastos fijos por mostrar</p>
        </Card>
      )}
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          static={true}
          className="z-[100]"
        >  
          <DialogPanel className={`h-96 w-full p-4`}>
            <h2 className="text-lg font-semibold mb-2">Editar fecha</h2>
            <div>
              <DatePicker
                lang='es' 
                value={selectedDate}
                selected={selectedDate}
                onValueChange={handleDateChange}
                locale={es}
                placeholder="Seleccionar"
                maxDate={new Date()}
                disabled={false}
                enableClear={false}
              />
            </div>
            <div className="flex justify-between mt-4 gap-2">
              <Button onClick={() => setIsDialogOpen(false)} variant="secondary" className="flex-1">Cerrar</Button>
              <Button onClick={handleSaveDate} className="flex-1">Actualizar fecha</Button>
            </div>
          </DialogPanel>
        </Dialog>
    </div>
  );
}

export default GastosTable
 