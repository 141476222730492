import React, { useEffect, useState } from 'react'
import { Dialog, DialogPanel, Table, TableBody, Button, DatePicker } from '@tremor/react';
import moment from 'moment';
import ManifiestoRow from './ManifiestoRow';
import { GET_MANIFIESTOS, UPDATE_MANIFIESTO_ENTRADA, UPDATE_MANIFIESTO_SALIDA } from '../../services/manifiestos/manifiestosService';
import { useMutation } from '@apollo/client';
import ManifiestoTableHead from './ManifiestoTableHead';
import { es } from 'date-fns/locale'
import Notification from '../shared/NotificationAlert';

const EditarManifiesto = ({residuoManifiesto, isEditarOpen, setIsEditarOpen, editData, idManifiestoEntrada, residuoManifiestoEntrada, totalCantidadLts }) => {
    const [updateManifiestoEntrada, { updatedEntradaManifiesto }] = useMutation(UPDATE_MANIFIESTO_ENTRADA, {
        refetchQueries: [
          {
              query: GET_MANIFIESTOS,
              variables: { residuo: residuoManifiesto === "aceite" ? residuoManifiesto : "resto" },
          },
        ]
      });
    const [updateManifiestoSalida, { updatedSalidaManifiesto }] = useMutation(UPDATE_MANIFIESTO_SALIDA, {
        refetchQueries: [
          {
              query: GET_MANIFIESTOS,
              variables: { residuo: residuoManifiesto === "aceite" ? residuoManifiesto : "resto"  },
          },
        ]
      });

      const [showNotification, setShowNotification] = useState(false);
      const [message, setMessage] = useState('');
      const [newRowData, setNewRowData] = useState({})
      const [selectedDate, setSelectedDate] = useState(editData?.fecha_entrada)

      useEffect(() => {
        if (isEditarOpen) {
          setSelectedDate(editData?.fecha_entrada);

        }
      }, [isEditarOpen, editData?.fecha_entrada]);
        
    const handleDateChange = (fecha) => {
        setSelectedDate(fecha);
    };

    const handleNewRowChange = (newData) => {
      setNewRowData(newData);
    };


      const handleEditarManifiesto = () => {
        newRowData.tipo === 'ENTRADA' ? 
        updateManifiestoEntrada({ 
          variables: {
              id: newRowData._id,
              data: {
              fecha_entrada: selectedDate,
              no_manifiesto: newRowData.no_manifiesto,
              razon_social: newRowData.razon_social,
              estado: newRowData.estado,
              residuo: newRowData.residuo,
              toxico: newRowData.toxico,
              inflamable: newRowData.inflamable,
              densidad: newRowData.densidad,
              cantidad_lts: newRowData.cantidad_lts,
              fase: newRowData.fase,
              transportista: newRowData.transportista,
              no_autorizacion_transportista: newRowData.no_autorizacion_transportista,
              cantidad_residuo: newRowData.cantidad_residuo,
              manifiesto: newRowData.manifiesto,
              destino_final: newRowData.destino_final,
              numero_manifiesto: newRowData.numero_manifiesto,
              no_autorizacion_destino_final: newRowData.no_autorizacion_destino_final,
              tipo: newRowData.tipo
            }
          } 
        }).then(result => {
            handleShowNotification(`Se actualizó el manifiesto de entrada con éxito.`)
        }).catch(error => {
            const errorMessage = error.message;
            handleShowNotification(errorMessage)
            console.log(errorMessage)
          }) :
          updateManifiestoSalida({ 
            variables: {
              id: idManifiestoEntrada,
              data: {
                residuo: residuoManifiestoEntrada,
                manifiestos_salida: [ {
                  fecha_entrada: selectedDate,
                  no_manifiesto: newRowData.no_manifiesto,
                  razon_social: newRowData.razon_social,
                  estado: newRowData.estado,
                  residuo: newRowData.residuo,
                  toxico: newRowData.toxico,
                  inflamable: newRowData.inflamable,
                  densidad: newRowData.densidad,
                  cantidad_lts: newRowData.cantidad_lts,
                  fase: newRowData.fase,
                  transportista: newRowData.transportista,
                  no_autorizacion_transportista: newRowData.no_autorizacion_transportista,
                  cantidad_residuo: newRowData.cantidad_residuo,
                  manifiesto: newRowData.manifiesto,
                  destino_final: newRowData.destino_final,
                  numero_manifiesto: newRowData.numero_manifiesto,
                  no_autorizacion_destino_final: newRowData.no_autorizacion_destino_final,
                  tipo: newRowData.tipo
                }
              ]
            }
          } 
        }).then(result => {
            handleShowNotification(`Se actualizó el manifiesto de salida con éxito.`)
        }).catch(error => {
            const errorMessage = error.message;
            handleShowNotification(errorMessage)
            console.log(errorMessage)
        });
        setIsEditarOpen(false)
      };

      const handleShowNotification = (message) => {
        setMessage(message)
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000); // Oculta la notificación después de 3 segundos
      };
  return (
    <div>
      <Dialog
        open={isEditarOpen}
        onClose={() => setIsEditarOpen(false)}
        static={true}
        className="z-[100]"
      >
        <DialogPanel className="max-w-full">
          <div className="overflow-x-auto mt-6 w-full">
            <div className='flex flex-col md:flex-row md:items-center justify-between mb-4 gap-3 md:gap-0'>
                <h2 className="text-lg font-semibold">Editar manifiesto</h2>
              <div className='flex flex-col md:flex-row md:justify-center md:items-center gap-5 mr-5'>
                <p>Actualizar fecha: </p>
                <DatePicker
                  lang='es'
                  className="md:w-1/3"
                  value={selectedDate ? new Date(selectedDate * 1000) : null}
                  selected={selectedDate ? new Date(selectedDate * 1000) : null}
                  onValueChange={value => handleDateChange(moment(value).unix())}
                  locale={es}
                  placeholder="Seleccionar"
                  maxDate={new Date()}
                  enableClear={false}
                />
                <Button
                    className="mx-auto flex items-center m-0"
                    onClick={() => handleEditarManifiesto()}
                >Actualizar
                </Button>
              </div>
            </div>
            <Table className="flex flex-col md:min-h-72 table-auto">
              <ManifiestoTableHead tipo={'editar'}/>
              <TableBody className='text-xs overflow-x-auto'>
                  <ManifiestoRow 
                    tipo={'editar'} 
                    manifiesto={editData}
                    handleDateChange={handleDateChange}
                    handleNewRowChange={handleNewRowChange}
                    newRowData={newRowData}
                    totalCantidadLts={totalCantidadLts}
                  />
              </TableBody>
            </Table>
          </div>
        </DialogPanel>
      </Dialog>
    {showNotification && <Notification message={message} />}
    </div>
  )
}

export default EditarManifiesto
