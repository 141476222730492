import React, { useCallback, useEffect, useState } from 'react';
import { es } from 'date-fns/locale'
import { DatePicker, Button, TableRow, TableCell, TextInput, BadgeDelta } from '@tremor/react';
import { RiArrowDownLine } from '@remixicon/react';
import moment from 'moment';

const ManifiestoRow = ({tipo, manifiesto, handleNewRowChange, handleAddManifiesto, newRowData, totalCantidadLts }) => {
  const initialCantidad = tipo === 'editar' ? totalCantidadLts.currentLts : totalCantidadLts.totalLts;

  const [rowState, setRowState] = useState({
    ...manifiesto,
    cantidad_lts: initialCantidad,
    cantidad_residuo: initialCantidad,
  });

  useEffect(() => {
    if (rowState) {
      handleNewRowChange(rowState);
    }
  }, [rowState, handleNewRowChange]);

  const handleChange = useCallback((name, value) => {
    setRowState(prevState => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };

      if (name === 'cantidad_lts' || name === 'cantidad_residuo') {
        const otherFieldName = name === 'cantidad_lts' ? 'cantidad_residuo' : 'cantidad_lts';
        updatedState[otherFieldName] = value;
      }

      return updatedState;
    });
  }, []);

  const fields = [
    'no_manifiesto', 'razon_social', 'estado', 'residuo', 'toxico',
    'inflamable', 'densidad', 'cantidad_lts', 'manifiesto', 'fase',
    'transportista', 'no_autorizacion_transportista', 'cantidad_residuo',
    'destino_final', 'numero_manifiesto', 'no_autorizacion_destino_final'
  ];
  return (
    <TableRow className='bg-gray-100'>
      {tipo === 'agregar' && 
        <TableCell className='flex flex-col gap-4'>
          <Button 
            className='bg-[#ee6a7b] border-transparent hover:border-transparent hover:bg-red-300'
            icon={RiArrowDownLine}
            onClick={handleAddManifiesto}
          />
        </TableCell>}
      {tipo === 'agregar' && <TableCell></TableCell>}
      {tipo !== 'agregar' &&
        <TableCell className="px-4 py-2">
          {manifiesto.tipo === 'SALIDA' ? (
            <BadgeDelta deltaType="decrease"></BadgeDelta>
          ) : (
            <BadgeDelta deltaType="increase"></BadgeDelta>
          )}
        </TableCell>}
      {tipo === 'agregar' &&
        <TableCell className="p-0 py-1.5 pr-1.5">
          <DatePicker
            lang='es'
            className="w-1/6"
            value={tipo === 'editar' ? new Date(manifiesto.fecha_entrada * 1000) : new Date()}
            selected={tipo === 'editar' ? new Date(manifiesto.fecha_entrada * 1000) : new Date()}
            onChange={value => handleChange('fecha_entrada', moment(value).unix())}
            locale={es}
            placeholderText="Seleccionar"
            maxDate={new Date()}
            enableClear={false}
          />
        </TableCell>}
      {fields.map(field => (
        <TableCell className="p-0 py-1.5 pr-1.5" key={field}>
          <TextInput
            type="text"
            name={field}
            value={rowState[field] || ''}
            onChange={e => handleChange(field, e.target.value)}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

// VALIDAR PORQUE NO TODOS LOS CAMPOS SE LLENAN Y QUE AL EDITAR SI ME REGRESE LA DATA QUE ESTOY INGRESANDO (CHECAR OTROS EJEMPLOS DE INPUT PARA HACER LO MISMO)
export default ManifiestoRow;
